import React, { useEffect, useState } from "react";
import checkPermission from "../../helpers/checkPermission";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getCurrentDate } from "../../helpers/getCurrentDate";
import { getCurrentTime } from "../../helpers/getCurrentTime";
import { CustomerDetail } from "../../components/appointments/CustomerDetail";
import { Listing } from "../../components/appointments/Listing";
import { validatePhone } from "../../helpers/validatePhone";
import { PackageForm } from "../../components/appointments/PackageForm";
import { debounce } from "lodash";
import { showToastMessage } from "../../helpers/toastr";
import { handlePackageListing, handleSavePackage } from "../../services/packageServices";
import { handleCustomerSearch } from "../../services/billingService";
import { handleEditCustomer } from "../../services/customerServices";
import { handleServiceListing } from "../../services/service";
import { selectOptions } from "../../helpers/paymentOption";
import { formatAmount } from "../../helpers/formatAmount";
import { roundOffAmount } from "../../helpers/roundOffAmount";
import wrapModalfn from "../../components/modal/modal";
import { formatAmountSymbol } from "../../helpers/formatAmountSymbol";
import { TableListing } from "../../components/appointments/TableListing";
import { discountValidate } from "../../helpers/discountValidate";

const AdvancePackage = ({ selectGlobalOrg }) => {
    const [packageData, setPackageData] = useState([]);
    const navigate = useNavigate();
    const [minval, setMinVal] = useState(null);
    const userData = useSelector((state) => state.userData);
    const [discountLimit, setDiscountLimit] = useState("50000000000000");
    const [showDiscount, setshowDiscount] = useState("none");
    const [showSpecialDiscount, setShowSpecialDiscount] = useState(false);
    const [showPackageForm, setShowPackageForm] = useState(false);

    const [typeBTN, setTypeBTN] = useState("savePrint");
    const [showDiv, setShowDiv] = useState("none");
    const [showGender, setShowGender] = useState("");
    const [showDetail, setShowDetail] = useState("none");
    const [searchdata, setSearchData] = useState([]);
    const [value, setValue] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [services, setServices] = useState([]);
    const [btn, setBtn] = useState(false);
    const [errors, setErrors] = useState({});
    const [showWallet, setShowWallet] = useState("none");
    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } =
        wrapModalfn();
    const [isVisible, setIsVisible] = useState([false]);


    const hasAdminRole = userData?.role?.role === "admin" ? true : false;
    const hasCoOwnerRole = userData?.role?.role === "co-owner" ? true : false;
    const [nameInputFocus, setNameInputFocus] = useState(false);
    const [contactInputFocus, setContactInputFocus] = useState(false);

    const [activeTab, setActiveTab] = useState("wallet");
    const [numPages, setNumPages] = useState(1);


    const [iframeModalIsOpen, iframeSetIsOpen] = useState(false);
  

    function openIframeModal(type) {
        iframeSetIsOpen(true);
    }

    function closeIframeModal() {
        iframeSetIsOpen(false);
    }
    const handlePackageChange = (updatedPackages) => {
        console.log(updatedPackages, 'Updated Packages');
        setPackages(updatedPackages);

        // Calculate the total original price of all services
        const originalPrice = updatedPackages.reduce((total, pkg) => {
            return total + pkg.services.reduce((pkgTotal, service) => pkgTotal + (parseFloat(service.total) || 0), 0);
        }, 0);

        // Update formData with calculated original price
        setFormData((prevState) => ({
            ...prevState,
            packages: updatedPackages,
            originalPrice: originalPrice, // Set original price
        }));
    };

    const [packages, setPackages] = useState([
        { packageName: "", serviceRows: [{ service: "", amount: "" }] },
    ]);
    const [formData, setFormData] = useState({
        // customer
        name: "",
        phone: "",
        email: "",
        gender: "male",
        orgId: "",
        wallet: 0,
        refId: "",
        type: "",
        date: getCurrentDate(),
        time: getCurrentTime(),

        // Service Billing
        billingRemark: "",
        subTotal: "",
        billingTax: "",
        discountSymbol: "%",
        discountedAmount: "",
        discount: "",
        paymentMode: "",
        multiplePaymentMethods: "",
        outStandingAmount: '',
        grandTotal: "",
        specialDiscount: "",
        walletStatus: false,
        walletAmount: "0.00",
        roundOffGrandTotal: 0,
        taxEnabled: false,
        dualPaymentEnabled: false,
        packages: [],
        packagePrice: 0,
        packageAdvance: 0,
        outstandingAmount:0
    });

    const [fields, setFields] = useState([
        { selectValue: "", inputValue: "" },
    ]);

    const handleSelectChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].selectValue = e.target.value;
        if (updatedFields[index].selectValue === "") {
            updatedFields[index].inputValue = ""
        }
        setFields(updatedFields);
        const newIsVisible = [...isVisible];
        newIsVisible[index] = true;
        setIsVisible(newIsVisible);
    };

    const handleMultiInputChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].inputValue = e.target.value;
        if (updatedFields[index].selectValue !== "") {
            setFields(updatedFields);
        }

    };

    const addSelectBox = () => {
        setFields([...fields, { selectValue: "", inputValue: "" }]);
    };

    const isMultiPaymentValid = () => {
        if (parseFloat(formData.discount) === 100) {
            return true;
        }

        return fields.every(field => (field.selectValue !== "" && field.selectValue.trim() !== ""));
    };
    const isMultiPaymentAmountValid = () => {
        const totalPaymentAmount = fields.reduce((sum, field) => {
            return sum + parseFloat(field.inputValue || 0);
        }, 0);

        // Check if the total payment amount equals the roundOffGrandTotal
        if (totalPaymentAmount !== parseFloat(formData.roundOffGrandTotal)) {
            return false;
        }

        return true;  // Return true if the total matches the grand total
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Ensure multiplePaymentMethods is an array (empty array if undefined)
        const paymentMethods = Array.isArray(formData.multiplePaymentMethods)
            ? formData.multiplePaymentMethods
            : [];

        // Check if name is empty
        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        // Check if payment mode is valid
        if (!isMultiPaymentValid()) {
            newErrors.paymentMode = "Payment mode is required for all fields";
            isValid = false;
        }
        if (!formData.packagePrice) {
            newErrors.packagePrice = "Package price is required";
            isValid = false;
        } else if (isNaN(formData.packagePrice) || formData.packagePrice <= 0) {
            newErrors.packagePrice = "Package price must be a positive number";
            isValid = false;
        }

     

        // Validate discount logic based on symbol (% or cash)
        const discount = parseFloat(formData.discount);
        const discountSymbol = formData.discountSymbol;
        const subtotal = parseFloat(formData.subTotal);
        const discountLimitAmount = subtotal * (discountLimit / 100);

        if (discountSymbol === "%") {
            // Allow discount to be 100% for admin (or general validation if applicable)
            if (discount === 100) {
                return isValid; // Allow the form to pass if discount is exactly 100%
            }

            // Ensure discount does not exceed the limit for other values
            if (discount > discountLimit) {
                newErrors.discount = `Discount cannot exceed ${discountLimit}%`;
                isValid = false;
            }
        } else {
            if (discount > discountLimitAmount) {
                if (
                    formData.specialDiscount === "" ||
                    isNaN(formData.specialDiscount) ||
                    formData.specialDiscount <= discountLimitAmount ||
                    formData.specialDiscount > subtotal
                ) {
                    newErrors.specialDiscount = `Special discount must be a number greater than ${discountLimitAmount} and less than or equal to ${subtotal}`;
                    isValid = false;
                }
            }
        }

        // Validate phone number
        const phoneValue = formData.phone.trim();

        if (!phoneValue) {
            newErrors.phone = "Phone is required";
            isValid = false;
        } else if (phoneValue.length !== 10) {
            newErrors.phone = "Phone number should be 10 numbers";
            isValid = false;
        } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
            newErrors.phone = "Invalid phone number format";
            isValid = false;
        }
        const packages = formData.packages || [];
        if (packages.length === 0 || !packages[0].packageName.trim()) {
            newErrors.packages = { packageName: "Package name is required" };
            isValid = false;
        }


        setErrors(newErrors);
        if (!isValid) {
            // Scroll to the first error if validation fails
            const firstErrorField = Object.keys(newErrors)[0];
            const errorElement = document.querySelector(`[name=${firstErrorField}]`);
            if (errorElement) {
                errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                errorElement.focus(); // Optional: Focus on the first error element
            }
        }
        return isValid;
    };
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        setFormData((prevFormData) => {
            const updatedFormData = {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value,
            };
    
            const packagePrice = parseFloat(updatedFormData.packagePrice || 0);
            const packageAdvance = parseFloat(updatedFormData.packageAdvance || 0);
    
            // Subtotal and Tax Calculation
            let subtotal = packagePrice;
            let totalTax = 0;
    
            if (updatedFormData.taxEnabled) {
                // Tax excluded from package price
                totalTax = packagePrice * 0.18; // Apply 18% tax rate
            } else {
                // Tax included in package price
                totalTax = (packagePrice * 0.18) / 1.18; // Reverse tax calculation
                subtotal = packagePrice - totalTax; // Adjust subtotal to exclude tax
            }
    
            totalTax = parseFloat(totalTax.toFixed(2)); // Round to 2 decimal places
    
            // Grand Total Calculation
            let grandTotal = packagePrice; // By default, matches package price
            if (updatedFormData.taxEnabled) {
                grandTotal += totalTax; // Add tax when excluded
            }
    
            // Wallet Deduction
            if (updatedFormData.walletStatus && updatedFormData.wallet > 0) {
                grandTotal = Math.max(
                    0,
                    grandTotal - Math.min(updatedFormData.wallet, grandTotal)
                );
            }
    
            // Discount Calculation
            let discountAmt = 0;
            const discount = parseFloat(updatedFormData.discount || 0);
            if (updatedFormData.discountSymbol === "%") {
                discountAmt = (discount / 100) * subtotal;
            } else {
                discountAmt = discount;
            }
    
            // Restrict discount for non-admin users
            if (!hasAdminRole) {
                discountAmt = Math.min(discountAmt, subtotal / 2);
            }
    
            const discountedAmount = parseFloat(discountAmt.toFixed(2));
            const roundOffGrandTotal = Math.round(grandTotal - discountedAmount);
    
            // Outstanding Amount Calculation (both cases)
            const outstandingAmount = Math.max(
                0,
                roundOffGrandTotal - packageAdvance
            );
    
            return {
                ...updatedFormData,
                subTotal: parseFloat(subtotal.toFixed(2)),
                billingTax: totalTax,
                discountedAmount,
                grandTotal: parseFloat(grandTotal.toFixed(2)),
                roundOffGrandTotal,
                outstandingAmount, // Always update outstanding amount
            };
        });
    
        // Clear errors for the field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };
    
    


    const handleKeyUp = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue);
    };

    const searchCustomerNumber = async (value) => {
        const orgID = Array.from(
            document.getElementById("orgId").selectedOptions,
            (option) => option.value
        );

        try {
            const result = await handleCustomerSearch(value, orgID);

            if (result.data) {
                if (result.data?.data.length > 0) {
                    setSearchData(result.data?.data);
                    setShowDiv("block");
                } else {
                    setShowDiv("none");
                    setSearchData([]);
                }
            }
        } catch (error) {
            showToastMessage("error", error.response.data.message);
        }
    };

    useEffect(() => {
        const debouncedFetchData = debounce(searchCustomerNumber, 300);

        const fetchDataOnChange = () => {
            debouncedFetchData(value);
        };

        if (value) {
            fetchDataOnChange(value);
        } else {
            setShowDiv("none");
            setSearchData([]);
            setValue("");
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [value, selectGlobalOrg]);

    const handleCustomerClick = async (id) => {
        try {
            const result = await handleEditCustomer(id);

            if (result.data) {
                setSelectedCustomer(result.data?.data);
                setShowDetail("block");
                setShowDiv("none");
                setShowGender("none");
            } else {
                setShowGender("");
                setShowDiv("none");
                setShowDetail("none");
                setSelectedCustomer([]);
            }
        } catch (error) {
            showToastMessage("error", error.response.data.message);
        }
    };

    useEffect(() => {
        if (selectedCustomer && selectedCustomer.length != 0) {
            let wallet;
            let status;
            if (selectedCustomer.wallet > 0) {
                setShowWallet("");
                status = true;
            } else {
                setShowWallet("none");
                status = false;
            }

            setFormData({
                name: selectedCustomer.name,
                phone: selectedCustomer.phone_number,
                uuid: selectedCustomer.uuid,
                email: selectedCustomer.email,
                gender: selectedCustomer.gender,
                dob: selectedCustomer.dob,
                orgId: selectedCustomer.org_id,
                customerId: selectedCustomer.id,
                wallet: selectedCustomer.wallet,
                type: "add",
                date: getCurrentDate(),
                time: getCurrentTime(),
                refId: "",

                billingRemark: "",
                subTotal: "0.00",
                billingTax: "0.00",
                discountSymbol: "%",
                discount: "",
                discountedAmount: "0.00",
                paymentMode: "",
                grandTotal: "0.00",
                specialDiscount: "",
                walletStatus: status,
                outStandingAmount: '',
                walletAmount: 0,
                roundOffGrandTotal: 0,
                taxEnabled: false,
                dualPaymentEnabled: false,
                packages: [],
                packagePrice: 0,
                packageAdvance: 0,
                outstandingAmount:0

            });
        } else {
            const newOrgId = document.getElementById("orgId").value;
            setFormData({
                // customer
                name: "",
                phone: "",
                email: "",
                gender: "male",
                orgId: newOrgId,
                refId: "",
                type: "add",
                wallet: 0,
                date: getCurrentDate(),
                time: getCurrentTime(),

                // Service Billing
                billingRemark: "",
                subTotal: "0.00",
                billingTax: "0.00",
                discountSymbol: "%",
                discount: "",
                discountedAmount: "0.00",
                paymentMode: "",
                walletStatus: false,
                specialDiscount: "",
                walletAmount: 0,
                grandTotal: "0.00",
                roundOffGrandTotal: 0,
                taxEnabled: false,
                dualPaymentEnabled: false,
                packages: [],
                packagePrice: 0,
                packageAdvance: "0.00",
                outstandingAmount:"0.00",
            });
        }
    }, [selectedCustomer]);

    useEffect(() => {

        fetchServices();
    }, [selectGlobalOrg]);



    const handleFieldChange = (fieldName, value) => {
        setFormData((prevState) => {
            const packagePrice = parseFloat(fieldName === "packagePrice" ? value : prevState.packagePrice || 0);
            let totalTax = 0;

            // Calculate tax
            if (prevState.taxEnabled) {
                totalTax = packagePrice * 0.18; // 18% tax
            } else {
                totalTax = (packagePrice * 0.18) / 1.18; // Tax as part of inclusive price
            }
            totalTax = parseFloat(totalTax.toFixed(4));

            // Calculate grand total
            let grandTotal = packagePrice + totalTax;

            // Handle discount calculations
            let discountAmt = 0;
            const discount = parseFloat(prevState.discount || 0);
            if (prevState.discountSymbol === "%") {
                discountAmt = (discount / 100) * packagePrice;
            } else {
                discountAmt = discount;
            }

            if (!hasAdminRole) {
                discountAmt = Math.min(discountAmt, packagePrice / 2); // Limit to 50% of subtotal
            }

            const discountedAmount = parseFloat(discountAmt.toFixed(4));
            const roundOffGrandTotal = Math.round(grandTotal - discountedAmount);

            // Calculate outstanding amount
            const customerPaid = parseFloat(fieldName === "customerPaid" ? value : prevState.customerPaid || 0);
            const outStandingAmount = Math.max(0, roundOffGrandTotal - customerPaid);

            return {
                ...prevState,
                [fieldName]: value,
                subTotal: packagePrice,
                billingTax: totalTax,
                discountedAmount,
                grandTotal: parseFloat(grandTotal.toFixed(4)),
                roundOffGrandTotal,
                outStandingAmount: parseFloat(outStandingAmount.toFixed(2)), // Ensure proper formatting
            };
        });
    };


    const fetchServices = async () => {
        const initialOrgIds = Array.from(
            document.getElementById("orgId").selectedOptions,
            (option) => option.value
        );
        const status = 0;

        try {
            const result = await handleServiceListing(initialOrgIds, status);
            if (result?.data) {
                setServices(result?.data.data);
            }
        } catch (error) {
            showToastMessage("error", error.response.data.message);
        }
    };

    const outstanding = formatAmountSymbol(
        Math.abs(selectedCustomer?.out_standing || 0)
    );
    const outstandingRaw = selectedCustomer?.out_standing || 0;
    const formattedOutstanding = formatAmountSymbol(Math.abs(outstandingRaw));

    const handleSubmit = async () => {

        if (validateForm()) {
            try {
                const newFormData = { ...formData, multiplePaymentMethods: fields }

                setFormData(newFormData);
                const initialOrgIds = Array.from(
                    document.getElementById("orgId").selectedOptions,
                    (option) => option.value
                );
                const result = await handleSavePackage(initialOrgIds, newFormData);
                if (result?.data) {
                    showToastMessage("success", "Package added successfully!");
                    navigate("/advance-package-listing");
                    setBtn(false);
                }

            } catch (error) {

            }

        }


    };

    const triggerBackspace = (e) => {
        const key = e.keyCode || e.which;

        if (key === 8) {
            setSelectedCustomer([]);
            setShowWallet("none");
        }

        if (e.ctrlKey && key === 88) {
            setSelectedCustomer([]);
            setShowWallet("none");
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-header bg-transparent">
                    <div class="row align-items-center">
                        <div className="col">
                            <h2 className="card-title heading-h3 mb-0">Advance Package</h2>
                        </div>
                        <div className="col-auto">
                            {checkPermission(userData, "View service-billings") && (
                                <NavLink
                                    to="/advance-package-listing"
                                    className="btn btn-primary modalButtonCommon"
                                >
                                    View Advance Packages
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <form id="billingform" autoComplete="off">
                        <div className="row">
                            <div className="col-xl-8 col-lg-7 col-md-12">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Mobile Number *</label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="phone"
                                                value={formData.phone}
                                                onKeyDown={(e) => {
                                                    validatePhone(e);
                                                    triggerBackspace(e);
                                                }}
                                                onChange={(e) => handleInputChange(e)}
                                                onKeyUp={handleKeyUp}
                                                onFocus={() => { setContactInputFocus(true), setNameInputFocus(false) }}
                                                className="form-control cust_mob"
                                                id="phone"
                                                placeholder="Enter Phone Number"
                                            />
                                            {contactInputFocus && <ul
                                                className="typeahead dropdown-menu"
                                                style={{ display: showDiv }}
                                            >
                                                <Listing
                                                    searchdata={searchdata}
                                                    handleCustomerClick={(id) => handleCustomerClick(id)}
                                                />
                                            </ul>}
                                            {errors.phone && (
                                                <div className="text-danger">{errors.phone}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Customer Name *</label>
                                            <input
                                                type="text"
                                                name="name"
                                                required=""
                                                className="form-control required name"
                                                onKeyUp={handleKeyUp} value={formData.name}
                                                onChange={(e) => handleInputChange(e)}
                                                onFocus={() => { setContactInputFocus(false), setNameInputFocus(true) }}
                                                id="name"
                                                placeholder="Enter Customer Name"
                                            />
                                            {nameInputFocus && <ul
                                                className="typeahead dropdown-menu"
                                                style={{ display: showDiv }}
                                            >
                                                <Listing
                                                    searchdata={searchdata}
                                                    handleCustomerClick={(id) => handleCustomerClick(id)}
                                                />
                                            </ul>}
                                            {errors.name && (
                                                <div className="text-danger">{errors.name}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6" style={{ display: showGender }}>
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Gender</label>
                                            <select
                                                name="gender"
                                                id="gender"
                                                value={formData.gender}
                                                onChange={(e) => handleInputChange(e)}
                                                className="cust_gender form-control form-select"
                                            >
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">How did you reach us?</label>
                                            <select
                                                className="form-control form-select"
                                                name="refId"
                                                value={formData.refId}
                                                onChange={(e) => handleInputChange(e)}
                                            >
                                                <option value="">Select Reference</option>

                                                <option value="insta">Instagram</option>
                                                <option value="fb">Facebook</option>
                                                <option value="walkin">Walkin</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Date</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="date"
                                                value={formData.date}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Date"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Time</label>
                                            <input
                                                className="form-control"
                                                type="time"
                                                name="time"
                                                value={formData.time}
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Time"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-12">
                                <blockquote className="blockquote blockquote-primary customer_detail round-10 ">
                                    <CustomerDetail selectedCustomer={selectedCustomer} />
                                </blockquote>
                            </div>
                        </div>

                        <div className="row">
                            <div className="table-billing-form" id="item_table">
                                <PackageForm services={services} onChange={handlePackageChange} errors={errors.packages || {}} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12 mt-4" id="package">
                                <div className="overflow-auto">
                                    <table className="table table-bordered table-billing billing-data">
                                        <tbody>
                                            <tr>
                                                <th>Caluclated Price</th>
                                                <td className="text-right">
                                                    <div className="col-sm-3 col-md-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="number"
                                                                className="form-control form-field-w"
                                                                value={formData.originalPrice || 0} // Display calculated original price
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="dual_payment">
                                                <th>Package Price</th>
                                                <td className="text-right">
                                                    <div className="col-sm-3 col-md-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="number"
                                                                className="form-control form-field-w"
                                                                name="packagePrice"
                                                                value={formData.packagePrice || ''}
                                                                onChange={(e) => {
                                                                    handleInputChange(e);

                                                                }}
                                                                onWheel={(e) => e.target.blur()}
                                                            />

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="dual_payment">
                                                <th>Advance Amount</th>
                                                <td className="text-right">
                                                    <div className="col-sm-3 col-md-3">
                                                        <div className="form-group">
                                                            <input
                                                                type="number"
                                                                className="form-control form-field-w"
                                                                name="packageAdvance"
                                                                value={formData.packageAdvance || ''}
                                                                onChange={(e) => {
                                                                    handleInputChange(e);
                                                                }}
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr className="dual_payment">
                                                <th>Enable Dual Payment</th>
                                                <td className="text-right">
                                                    <div className="form-group mb-0 d-flex justify-content-end">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check m-0"
                                                                name="dualPaymentEnabled"
                                                                checked={formData.dualPaymentEnabled}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Payment Mode</th>
                                                <td>
                                                    {fields.map((field, index) => (
                                                        <>
                                                            <div className={`row flex-nowrap ${index > 0 ? "mt-3" : ""}`}>
                                                                <div className="col">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <select
                                                                                key={index}
                                                                                className="form-select form-control form-field-w"
                                                                                name="paymentMode"
                                                                                value={field.selectValue}
                                                                                onChange={(event) => {
                                                                                    handleSelectChange(event, index);
                                                                                    handleInputChange(event);
                                                                                }}
                                                                            >
                                                                                <option value="">Select Payment Mode</option>
                                                                                {selectOptions(null, index)}
                                                                            </select>
                                                                        </div>

                                                                        {isVisible[index] === true && field.selectValue !== "" && (
                                                                            <div className="col">
                                                                                <input
                                                                                    min="1"
                                                                                    type="number"
                                                                                    className="form-control form-field-w"
                                                                                    value={field.inputValue}
                                                                                    onChange={(e) => {
                                                                                        handleMultiInputChange(e, index);
                                                                                        errors.paymentMode = "";
                                                                                    }}
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-auto">
                                                                    {fields.length > 1 && index !== fields.length - 1 && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => {
                                                                                const updatedFields = fields.filter((_, i) => i !== index);
                                                                                setFields(updatedFields);
                                                                            }}
                                                                            id="btn_remove_row"
                                                                            className="button button-primary btn-icon"
                                                                        >
                                                                            <i className="fa fa-minus"></i>
                                                                        </button>
                                                                    )}

                                                                    {index === fields.length - 1 && formData.dualPaymentEnabled && (
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setFields([...fields, { selectValue: "", inputValue: "" }])
                                                                            }
                                                                            id="btn_row"
                                                                            className="button button-primary btn-icon"
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </td>
                                                {errors.paymentAmount && (
                                                    <div className="text-danger">
                                                        {errors.paymentAmount}
                                                    </div>
                                                )}
                                            </tr>

                                            <tr className="amount_due tax-include">
                                                <th>Tax Exclude/Include</th>
                                                <td className="text-right">
                                                    <div className="form-group mb-0 d-flex justify-content-end">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                name="taxEnabled"
                                                                checked={formData.taxEnabled || false}
                                                                onChange={(e) => handleInputChange(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {showDiscount && (
                                                <tr>
                                                    <th
                                                        className="paddingbottom-25"
                                                        style={{ verticalAlign: "middle" }}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <label className="pe-1">Discount</label>
                                                        </div>
                                                    </th>
                                                    <td className="text-right position-relative paddingbottom-25">
                                                        <div className="discount-wrap">
                                                            <div className="discount-wrap-field">
                                                                <select
                                                                    className="text-right input-xs discount_mode form-select form-control"
                                                                    id="discount_mode"
                                                                    value={formData.discountSymbol}
                                                                    onChange={(e) => {
                                                                        handleInputChange(e);

                                                                    }}
                                                                    name="discountSymbol"
                                                                >
                                                                    <option value="%">%</option>
                                                                    <option value="₹">₹</option>
                                                                </select>
                                                            </div>
                                                            <input
                                                                className="text-right discount input-sm form-control form-field-w"
                                                                id="discount"
                                                                value={formData.discount}
                                                                name="discount"
                                                                type="text"
                                                                onKeyDown={(e) => discountValidate(e, hasAdminRole)} // Passing hasAdminRole here
                                                                onChange={(e) => {
                                                                    handleInputChange(e);

                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            id="discount_value"
                                                            className="text-start discount-amount"
                                                        >
                                                            {formData?.discountedAmount > "0.00"
                                                                ? formData?.discountedAmount
                                                                : ""}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}


                                            {showSpecialDiscount && !hasAdminRole && (
                                                <tr className="amount_due">
                                                    <th>Special Discount:</th>
                                                    <td className="text-right">
                                                        <div className="form-group mb-0">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <input
                                                                    className="text-right discount input-sm form-control"
                                                                    step="any"
                                                                    min="0"
                                                                    max="100"
                                                                    value={formData?.specialDiscount}
                                                                    name="specialDiscount"
                                                                    type="text"
                                                                    onKeyDown={(e) => discountValidate(e, hasAdminRole)}
                                                                    onChange={(e) => {
                                                                        handleInputChange(e);
                                                                    }}
                                                                />
                                                            </div>
                                                            {errors.specialDiscount && (
                                                                <div className="text-danger">
                                                                    {errors.specialDiscount}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <p className="special-discount-text">
                                                            *You can apply a maximum discount of {discountLimit}
                                                            {formData.discountSymbol}. For discounts exceeding
                                                            this limit, please submit a request to the admin.
                                                        </p>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th style={{ width: "50%" }}>Sub Total</th>
                                                <td className="text-right">
                                                    <span id="subTotal">{formData?.subTotal || 0.0}</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>Tax (18%)</th>
                                                <td className="text-right">
                                                    <span id="taxTotal">{formData?.billingTax || 0.0}</span>
                                                </td>
                                            </tr>





                                            <tr className="amount_due" style={{ display: showWallet }}>
                                                <th>Use Wallet Amount:</th>
                                                <td className="text-right">
                                                    <div className="form-group mb-0">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check m-0"
                                                                name="walletStatus"
                                                                checked={formData.walletStatus}
                                                                onChange={(e) => {
                                                                    handleInputChange(e);
                                                                    handleFieldChange(
                                                                        "walletStatus",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            />
                                                            <span
                                                                className="currencySymbol"
                                                                style={{ display: "inline-block" }}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr className="amount_due">
                                                <th>Grand Total:</th>
                                                <td className="text-right">
                                                    <span
                                                        className="currencySymbol"
                                                        style={{ display: "inline-block" }}
                                                    ></span>
                                                    <span id="grandTotal">
                                                        {formatAmount(formData?.roundOffGrandTotal)}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="amount_due">
                                                <th>Outstanding:</th>
                                                <td className="text-right">
                                                    <span className="currencySymbol" style={{ display: 'inline-block' }}></span>
                                                    <span id="outStandingAmount">
                                                        {formData.outstandingAmount ? formData.outstandingAmount : "0.00"}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex flex-wrap gap-2">
                                    <button
                                        type="button"
                                        onClick={() => handleSubmit("save")}
                                        disabled={btn}
                                        className="btn btn-primary table-billing-btn"
                                    >
                                        Save Bill
                                    </button>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AdvancePackage;
