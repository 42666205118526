import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { showToastMessage } from '../../helpers/toastr';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { handleStaff } from '../../services/staffServices';
import { validatePhone } from '../../helpers/validatePhone';
Modal.setAppElement('#root')


const StaffModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen, fetchData, selectedStaff }) => {

  const [btn, setBtn] = useState(false);

  const userData = useSelector((state) => state.userData);

  const [selectedOption, setSelectedOption] = useState([]);

  const getOrganisationId = (arr) => {

    const values = arr.map((el) => el.organisation_id);

    setSelectedOption([...values]);

  };



  useEffect(() => {

    if (type === 'edit' && selectedStaff) {

      if (selectedStaff.get_orgnaisation) {
        getOrganisationId(selectedStaff.get_orgnaisation)
      }

      setFormData({
        uuid: selectedStaff.uuid,
        name: selectedStaff.name,
        phone: selectedStaff.phone_number,
        email: selectedStaff.email ?? '',
        role: selectedStaff && selectedStaff.role ? selectedStaff.role.role : '',
        joiningDate: selectedStaff.joining_date,
        orgId: selectedOption,
        type: type,
        id: selectedStaff.id,
        status: selectedStaff.status == 0 ? true : false,
        salary:selectedStaff.salary ?? 0

      });

      if (selectedStaff && selectedStaff.role && selectedStaff.role.role) {

        if (selectedStaff.role.role === 'co-owner' || selectedStaff.role.role === 'accountant') {
          setOrg(true);
        } else {
          setOrg(false);

        }
      }

    }
    else {
      const newOrgId = document.getElementById('orgId').value;
      setFormData({
        name: '',
        phone: '',
        email: '',
        role: '',
        joiningDate: '',
        status: true,
        orgId: [newOrgId],
        type: type,
        salary:0
      });
      setOrg(false);
      setSelectedOption([]);
    }

    setErrors({});

  }, [modalIsOpen, type]);

  useEffect(() => {
    if (type === 'edit') {
      setFormData(prevState => ({
        ...prevState,
        orgId: selectedOption
      }));
    }
  }, [selectedOption]);


  const [showOrg, setOrg] = useState(false);

  const roles = [
    { id: 1, name: 'Co Owner', slug: 'co-owner' },
    { id: 2, name: 'Accountant', slug: 'accountant' },
    { id: 3, name: 'Manager', slug: 'manager' },
    { id: 4, name: 'Employee', slug: 'employee' },
  ];

  const options = [];

  if (userData && userData.get_orgnaisation.length > 0) {
    userData.get_orgnaisation.forEach((el) => {
      options.push({
        value: el.organisation_id,
        label: el.organisation.name,
      });
    });
  }



  const headerText = type === "add" ? 'Add Staff' : 'Edit Staff';

  const current = new Date().toISOString().split("T")[0]

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    role: '',
    joiningDate: '',
    orgId: [],
    type: '',
    status: true,
    salary:0
  });


  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    // if (!formData.email.trim()) {
    //   newErrors.email = "Email is required";
    //   isValid = false;
    // }else if (formData.email.trim() && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email.trim())) {
    //   newErrors.email = "Please enter a valid email address";
    //   isValid = false;
    // }

    if (formData.email.trim() && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }


    if (!formData.role.trim()) {
      newErrors.role = "Role is required";
      isValid = false;
    }

    if (showOrg === true && (!formData.orgId || formData.orgId.length === 0)) {
      newErrors.orgId = "Organisation is required";
      isValid = false;
    }



    // Validate phone
    // const phoneValue = String(formData.phone).trim(); // Convert to string before calling trim
    // if (!phoneValue) {
    //   newErrors.phone = "Phone is required";
    //   isValid = false;
    // }

    // if (phoneValue.length !== 10) { // Check for exactly 10 digits
    //   newErrors.phone = "Phone number should be 10 digits";
    //   isValid = false;
    // }

    const phoneValue = String(formData.phone).trim();

    if (!phoneValue) {
      newErrors.phone = "Phone is required";
      isValid = false;
    } else if (phoneValue.length !== 10) {
      newErrors.phone = "Phone number should be 10 digits";
      isValid = false;
    } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
      newErrors.phone = "Invalid phone number format";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };



  const handleInputChange = (e) => {

    const { name, value, type, checked } = e.target;
    const processedValue = type === 'number' ? parseFloat(value) : value;

    if (e.target.name === 'role') {

      if (e.target.value === 'co-owner' || e.target.value === 'accountant') {
        setOrg(true);
        setSelectedOption([]);
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['orgId']: [],
        }));
      } else {
        setOrg(false);
        const newOrgId = document.getElementById('orgId').value;
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['orgId']: [newOrgId],
        }));
      }
    }



    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));

    if (name === 'status') {

      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: checked
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));


  };

  const saveStaff = async (type) => {

    if (validateForm()) {

      setBtn(true);

      // console.log(formData,'formData()');

      // return;

      try {
        const reason = selectedStaff.reason
        const result = await handleStaff(formData, reason);

        if (result && result.data && result.data.success === true) {
          showToastMessage('success', result.data.message)
          fetchData()
          closeModal();
          setBtn(false);
        }

      } catch (error) {
        console.log(error);
        setBtn(false);
        if (error.response.status === 404) {
          const errors = error.response.data.message
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
              errors[key].forEach((message) => {
                showToastMessage('error', message)
              });
            });
          }
        } else {
          showToastMessage('error', error.response.data.message)
        }
      }


    }
  };


  // handleStaffListing

  return <>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
        <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
      </div>

      <form className="mx-auto mt-4 form" style={{ maxWidth: '100%' }}>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="name" className="form-label">Full Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your name"
              />
              {errors.name && (
                <div className="text-danger">{errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="phoneNumber" className="form-label">Mobile Number:</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phone"
                value={formData.phone}

                placeholder="Enter your phone number"
                onKeyDown={(e) => validatePhone(e)}
                onChange={(e) => handleInputChange(e)}
              />
              {errors.phone && (
                <div className="text-danger">{errors.phone}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your email address"
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="joiningDate" className="form-label">Joining Date:</label>
              <input
                type='date'
                className="form-control"
                id='joiningDate'
                name='joiningDate'
                value={formData.joiningDate}
                onChange={(e) => handleInputChange(e)}
                placeholder='Enter BirthDate'
                max={current}
              />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="role" className="form-label">Role:</label>
              <select
                className="form-control form-select"
                id='role'
                style={{ paddingRight: '40px' }}
                name='role'
                value={formData.role}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select Role</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.slug}>
                    {role.name}
                  </option>
                ))}
              </select>
              {errors.role && (
                <div className="text-danger">{errors.role}</div>
              )}
            </div>
          </div>
          {showOrg === true ? (
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label htmlFor="org" className="form-label">Select Organisation:</label>
                <Select
                  className="multiselect form-control"
                  id='org'
                  name='orgId'
                  isMulti={true}
                  // defaultValue={options.filter((option) => selectedOption.includes(option.value))}
                  value={options.filter((option) => selectedOption.includes(option.value))}

                  onChange={(selectedOption) => {

                    if (selectedOption) {
                      console.log("if");
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        orgId: selectedOption.map(option => option.value),
                      }));
                      setSelectedOption(selectedOption.map(option => option.value));
                    } else {
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        orgId: [],
                      }));
                      setSelectedValues([]);
                    }
                  }}

                  options={options}
                />
                {errors.orgId && (
                  <div className="text-danger">{errors.orgId}</div>
                )}

              </div>
            </div>
          ) : null}
          {formData.role === 'employee' || formData.role === 'manager' && formData.orgId !== null ? (
            <div className="col-md-6 col-12">
              <div className="form-group">
                <label htmlFor="phoneNumber" className="form-label">Employee Salary:</label>
                <input
                  type="number"
                  className="form-control"
                  id="salary"
                  name="salary"
                  value={formData.salary}

                  placeholder="Enter employee salary"
                  onChange={(e) => handleInputChange(e)}
                />
                {errors.phone && (
                  <div className="text-danger">{errors.phone}</div>
                )}
              </div>
            </div>
          ) : null}
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="status" className="form-label">Status:</label>
              <div class="form-check">

                <input
                  type='checkbox'
                  className="form-check"
                  id='status'
                  name='status'
                  value={formData.status}
                  checked={formData.status}
                  onChange={(e) => handleInputChange(e)}
                />
                <label for="status">
                  <span class="checkbox">
                    <i class="mdi mdi-check menu-icon"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 text-end">
            <button
              type="button"
              disabled={btn}
              className="button button-primary button-icon-left"
              onClick={() => saveStaff(type)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </>
}

export default StaffModal;
