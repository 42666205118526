import { useEffect, useState } from "react";
import wrapModalfn from "../../components/modal/modal";
import ExpensesModal from "../../components/expenses/ExpensesModal";
import { handleDeleteExpense, handleEditExpense, handleExpenseListing, handleCategoryFilter } from "../../services/expense";


import DataTable from "react-data-table-component";
import { formatAmountSymbol } from "../../helpers/formatAmountSymbol";
import { showToastMessage } from "../../helpers/toastr";
import Loading from "../../components/loader/Loading";

import ConfirmationPopupButton from "../../components/ConfirmationPopup/ConfirmationPopupButton";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import { formatDate } from "../../helpers/formatDate";
import { debounce } from 'lodash';
import SearchFilter from "../../components/pagination/SearchFilter";
import { useSelector } from "react-redux";
import checkPermission from "../../helpers/checkPermission";
import showDeletePopup from "../../helpers/showDeletePopup";
import { handleGetOtp } from "../../services/billingService";
import showOtpVerficationPopup from "../../helpers/showOtpVerficationPopup";
import { handleOtpVerfication } from "../../services/billingService";
import { conditionalRowStyles } from "../../helpers/conditionalRowStyles";
import showEditPopup from "../../helpers/showEditPopup";
import ReasonTooltip from '../customer/ReasonTooltip.jsx';

const Expenses = ({ selectGlobalOrg, filterData }) => {
    const userData = useSelector((state) => state.userData);
    const [popupID, setPopupID] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedService, setSelectedService] = useState([]);


    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selection, setSelection] = useState("Last-30-Days");

    const [dateInputValue, setDateInputValue] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [grandTotal, setGrandTotal] = useState(0);
    const [categoryTotals, setCategoryTotals] = useState([]);
    const [newFilterData, setNewFilterData] = useState([]);
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;
    const [filterDate, setFilterDate] = useState('');

    const filterDataByDateForCategories = (data) => {
        if (!startDate || !endDate) {
            return data; // Return unfiltered data if no date is selected
        }

        const start = new Date(startDate);
        const end = new Date(endDate);

        return data.filter((item) => {
            const itemDate = new Date(item.date); // Ensure item.date exists and is valid
            return itemDate >= start && itemDate <= end;
        });
    };

    const filterDataByDate = (data) => {
        if (!startDate || !endDate) {
            return data; // Return unfiltered data if no date is selected
        }

        const start = new Date(startDate);
        const end = new Date(endDate);

        return data.filter((item) => {
            const itemDate = new Date(item.date); // Ensure item.date exists and is valid
            return itemDate >= start && itemDate <= end;
        });
    };


    const calculateCategoryTotals = () => {
        const filteredData = filterDataByDateForCategories(data?.data); // Filter for category totals only

        const categoryTotals = filteredData?.reduce((acc, item) => {
            const category = item?.get_cat_name?.name || "Unknown Category";
            const total = item?.total || 0;

            if (!acc[category]) {
                acc[category] = { category, total: 0 };
            }

            acc[category].total += parseFloat(total);
            return acc;
        }, {});

        return Object.values(categoryTotals);
    };

    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };

    const handleSelectionChange = (e) => {
        setSelection(e.target.value);
        if (
            e.target.value === "Month" ||
            e.target.value === "Today" ||
            e.target.value === "Overall" ||
            e.target.value === "Year" ||
            (e.target.value === "Date" && dateInputValue) ||
            (e.target.value === "CustomDate" && startDate && endDate)
        ) {
            setSelection(e.target.value);
        }
    };

    const handleDateInputChange = (e) => {
        if (selection === "Date" && e.target.value) {
            setDateInputValue(e.target.value);
        }
    };

    const handleStartDateChange = (e) => {
        if (selection === "CustomDate" && e.target.value) {
            setStartDate(e.target.value);
        }
    };

    const handleEndDateChange = (e) => {
        if (selection === "CustomDate" && e.target.value) {
            setEndDate(e.target.value);
        }
    };

    useEffect(() => {

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue);
        };
        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }
        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue]);

    useEffect(() => {
        if (
            (selection === "Date" && !dateInputValue) ||
            (selection === "CustomDate" && (!startDate || !endDate))
        ) {
            return;
        }
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        switch (selection) {
            case 'Today':
                const todayDate = today.toISOString().split('T')[0];
                setDateInputValue(todayDate);
                setFilterDate(todayDate);
                setStartDate('');
                setEndDate('');
                break;
            case 'Yesterday':
                const yesterdayDate = yesterday.toISOString().split('T')[0];
                setDateInputValue(yesterdayDate);
                setFilterDate(yesterdayDate);
                setStartDate('');
                setEndDate('');
                break;
            case 'Last-7-Days':
                const last7Days = [];
                for (let i = 6; i >= 0; i--) {
                    const lastDate = new Date();
                    lastDate.setDate(today.getDate() - i);
                    last7Days.push(lastDate.toISOString().split('T')[0]); // format: YYYY-MM-DD
                }
                setFilterDate(last7Days.join(', ')); // Join dates with a comma
                setStartDate(new Date(today.setDate(today.getDate() - 7)).toISOString().split('T')[0]);
                setEndDate(today.toISOString().split('T')[0]);
                break;
            case 'Last-14-Days':
                const last14DaysStart = new Date(today);
                last14DaysStart.setDate(today.getDate() - 14);
                setStartDate(last14DaysStart.toISOString().split('T')[0]);
                setEndDate(today.toISOString().split('T')[0]);
                setFilterDate(`From ${last14DaysStart.toISOString().split('T')[0]} to ${today.toISOString().split('T')[0]}`);
                break;
            case 'Last-30-Days':
                const last30DaysStart = new Date(today);
                last30DaysStart.setDate(today.getDate() - 30);
                setStartDate(last30DaysStart.toISOString().split('T')[0]);
                setEndDate(today.toISOString().split('T')[0]);
                setFilterDate(`From ${last30DaysStart.toISOString().split('T')[0]} to ${today.toISOString().split('T')[0]}`);
                break;
            case 'Overall':
                setStartDate('');
                setEndDate('');
                setDateInputValue('');
                setFilterDate('');
                break;
            case 'CustomDate':
                setFilterDate(`From ${startDate} to ${endDate}`); // Display selected custom date range
                break;
            case 'Date':
                setStartDate('');
                setEndDate('');
                setFilterDate(dateInputValue);
                break;
            default:
                break;
        }
        fetchFilteredData();
    }, [selection, dateInputValue, startDate, endDate]);

    useEffect(() => {
        fetchFilteredData();
    }, []);

    const fetchFilteredData = async () => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const formData = {
            filterType: selection,
            filterPeriod: dateInputValue,
            startDate: startDate,
            endDate: endDate,
        };
        try {
            const result = await handleCategoryFilter(initialOrgIds, JSON.stringify(formData));
            console.log('Filtered Categories:', result);
            setNewFilterData(result?.data?.data);
        } catch (error) {
            console.log(error);
            showToastMessage('error', error?.response?.data?.message)
        }
    };

    const fetchData = async (page, per_page, searchText) => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        const formData = {
            orgID: initialOrgIds,
        };

        try {
            const result = await handleExpenseListing(initialOrgIds, JSON.stringify(formData), page, per_page, searchText);
            if (result.data) {
                const expensesData = result.data.data;
                setData(expensesData);

                // Calculate grand total
                // const total = expensesData.reduce((acc, item) => acc + (item.total || 0), 0);
                // setGrandTotal(total);

                const categoryTotalMap = expensesData.data.reduce((acc, item) => {
                    const categoryName = item.get_cat_name?.name || 'Unknown Category';
                    if (!acc[categoryName]) {
                        acc[categoryName] = 0;
                    }
                    acc[categoryName] += item.total || 0;
                    return acc;
                }, {});

                // Convert categoryTotalMap to array of { categoryName, total } objects
                const categoryTotalsArray = Object.keys(categoryTotalMap).map((categoryName) => ({
                    categoryName,
                    total: categoryTotalMap[categoryName]
                }));
                setCategoryTotals(categoryTotalsArray);

            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error?.response?.data?.message)
        } finally {
            setLoading(false)
        }

    }


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
            cell: row => (
                <span title={row.name}>
                    {row.name}
                </span>
            )
        },

        {
            name: 'Category',
            selector: row => row.get_cat_name?.name,
            sortable: true,
            sortFunction: (a, b) => a.get_cat_name.name.localeCompare(b.get_cat_name.name),
        },

        {
            name: 'Total',
            selector: row => row?.total ? formatAmountSymbol(row?.total) : formatAmountSymbol('0.00'),
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.total) - parseFloat(b.total),
            cell: row => formatAmountSymbol(row?.total || '0.00'),
        },

        {
            name: 'Type',
            selector: row => row && row.type == 0 ? 'Cash Expense' : 'Bank Expense',
            sortable: true,
            sortFunction: (a, b) => {
                const statusA = a.type === 0 ? 'Cash Expense' : 'Bank Expense';
                const statusB = b.type === 0 ? 'Cash Expense' : 'Bank Expense';
                return statusA.localeCompare(statusB);
            }
        },

        {
            name: 'Expense Date',
            selector: row => row.expense_date ? formatDate(row.expense_date) : '',
            sortable: true,
        },

        {
            name: 'Note',
            selector: row => row.notes,
            sortable: true,
            cell: row => {
                if (row.notes) {
                    const maxLength = 15; // Set your preferred character limit
                    const displayText = row.notes.length > maxLength
                        ? `${row.notes.substring(0, maxLength)}...`
                        : row.notes;

                    return (
                        <ReasonTooltip reason={row.notes}>
                            <span>{displayText}</span>
                        </ReasonTooltip>
                    );
                } else {
                    return '';
                }
            }
        }

    ];

    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request ? row.has_latest_delete_request.reason : '',
            sortable: true,
            cell: row => {
                if (row?.has_latest_delete_request) {
                    const reason = row.has_latest_delete_request.reason;
                    return <ReasonTooltip reason={reason} />;
                } else {
                    return '';
                }
            }
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update expense') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you can not edit it.');
                            } else {
                                handleEdit(row.id, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}
                {checkPermission(userData, 'Delete expense') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.id)
                            }
                        }}
                    />
                )}

                {/* <button className="icon-sm-medium round-100 bg-white icontext-red borderwidth-0" onClick={() => handleDelete(row.id)}><i class="mdi mdi-delete-empty menu-icon bodytext-24"></i></button> */}
                {/* Add more action buttons as needed */}
            </div>
        ),
    });


    const handleDelete = async (id) => {
        const reason = await showDeletePopup();
        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteExpense(id, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage)
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const result = await handleGetOtp(id);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(id, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteExpense(id, reason);
                            fetchData(currentPage, perPage)
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message)
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Edit operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }

    };


    const handleEdit = async (id, type) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleEditExpense(id);

                    if (result.data) {
                        setSelectedService(result.data?.data)
                        setSelectedService(preService => ({
                            ...preService,
                            reason: reason,
                        }));
                        openModal(type)
                    }

                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message)
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(id, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(id, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    const result = await handleEditExpense(id);

                                    if (result.data) {
                                        setSelectedService(result.data?.data)
                                        setSelectedService(preService => ({
                                            ...preService,
                                            reason: reason,
                                        }));
                                        openModal(type)
                                    }

                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message)
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };

    return (
        <>
            {/* <ConfirmationPopup
        handleDelete={handleDelete}
        popupID={popupID}
        setPopupID={setPopupID}
    /> */}

            <div className="card">
                <div className="card-header bg-transparent">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="card-title heading-h3 mb-0">Expenses</h2>
                        </div>
                        <div className="col-auto">
                            {checkPermission(userData, 'Create expense') && (
                                <button
                                    type="button"
                                    onClick={() => openModal('add')}
                                    className="btn btn-primary button-icon-left mt-2"
                                >
                                    <i className="fa fa-plus-circle"></i> Add Expense
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <ExpensesModal
                        modalIsOpen={modalIsOpen}
                        setIsOpen={setIsOpen}
                        closeModal={closeModal}
                        customStyles={customStyles}
                        fetchData={() => fetchData(currentPage, perPage)}
                        type={type}
                        selectGlobalOrg={selectGlobalOrg}
                        selectedService={selectedService}
                    />

                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <DataTable
                                columns={columns}
                                data={data?.data}
                                pagination
                                paginationPerPage={25}
                                paginationServer
                                paginationTotalRows={data?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                subHeader
                                subHeaderComponent={
                                    <SearchFilter
                                        setSearchValue={setSearchValue}
                                        searchValue={searchValue}
                                    />
                                }
                                paginationComponentOptions={{
                                    rangeSeparatorText: 'of',
                                    noRowsPerPage: true,
                                }}
                                {...(hasAdminRole && { conditionalRowStyles })}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="card mt-4">
                <div className="card-header bg-transparent">
                    <div className="row align-items-center">
                        <div className="col">
                            <h2 className="card-title heading-h3 mb-0">Category Totals</h2>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex gap-2">
                                <div className="input-date">
                                    <select className="form-control form-select" value={selection} onChange={handleSelectionChange}>
                                        <option value="Today">Today</option>
                                        <option value="Yesterday">Yesterday</option>
                                        <option value="Date">Date</option>
                                        <option value="Last-7-Days">Last 7 Days</option>
                                        <option value="Last-14-Days">Last 14 Days</option>
                                        <option value="Last-30-Days">Last 30 Days</option>
                                        <option value="Overall">Overall</option>
                                        <option value="CustomDate">Custom Date</option>
                                    </select>
                                </div>

                                {selection === 'CustomDate' ? (
                                    <>
                                        <div className="input-date">
                                            <input
                                                type="date"
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="input-date">
                                            <input
                                                type="date"
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </>
                                ) : selection === 'Date' ? (
                                    <div className="input-date">
                                        <input
                                            type="date"
                                            value={dateInputValue}
                                            onChange={handleDateInputChange}
                                            className="form-control"
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        columns={[
                            {
                                name: 'Category',
                                selector: (row) => row.categoryName, // Use categoryName from API response
                                sortable: true,
                            },
                            {
                                name: 'Date', // New column for the selected filter date
                                selector: () => filterDate, // Use filterDate here
                                sortable: true,
                            },
                            {
                                name: 'Total Amount',
                                selector: (row) => formatAmountSymbol(row.total), // Use total from API response
                                sortable: true,
                            },
                        ]}
                        data={newFilterData} // Display only filtered category data
                        pagination={false}
                        noDataComponent={<div>No records to display</div>}
                    />
                </div>
            </div>
        </>
    );


}

export default Expenses;