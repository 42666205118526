import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageData } from "../../helpers/utils";
import { showToastMessage } from "../../helpers/toastr";
import ReactLogo from '../../assets/images/HairRaiserz.png'
import { handleLogin, handleForgetPassword } from '../../services/getLoggedUserData.js';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../actions/dataAction.js';
import Loginimage from '../../assets/images/loginbg.jpg';
import { validatePhone } from "../../helpers/validatePhone.js";



export const Login = ({ onLogin }) => {

  const [btn, setBtn] = useState(false);

  const [showForm, setShowForm] = useState(0);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    phone_number: '',
    password: '',
    loginType: 0,
  });

  const [forgetPasswordData, setForgetPasswordData] = useState({
    email: '',
  });

  const [errors, setErrors] = useState({});
  const [showForgetPassword, setShowForgetPassword] = useState(false);

  const handleShowForm = (value) => {

    if (value == 0) {

      setFormData({
        ...formData,
        phone_number: '',

      });

    } else {
      setFormData({
        ...formData,
        email: '',
      });
    }

    setShowForm(value)

  }

  const handleInputChange = (e, formType) => {

    const { name, value } = e.target;

    if (formType === 'login') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

    } else if (formType === 'forgetPassword') {
      setForgetPasswordData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }


    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

  };

  // loginType


  const validateForm = (type) => {

    let isValid = true;
    const newErrors = {};

    if (type === 'login') {

      if (showForm == 1) {
        if (!formData.email) {
          newErrors.email = "Email is required";
          isValid = false;
        } else if (formData.email.trim() && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email.trim())) {
          newErrors.email = "Please enter a valid email address";
          isValid = false;

        }
      } else {
        if (!formData.phone_number) {
          newErrors.phone_number = "Mobile number is required";
          isValid = false;
        } else if (formData.phone_number.trim() && !/^\d{10}$/.test(formData.phone_number.trim())) {
          newErrors.phone_number = "Please enter a valid mobile number";
          isValid = false;

        }
      }





      // Validate password
      if (!formData.password.trim()) {
        newErrors.password = "Password is required";
        isValid = false;
      }
    } else {

      if (!forgetPasswordData.email) {
        newErrors.emails = "Email is required";
        isValid = false;
      } else if (forgetPasswordData.email.trim() && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(forgetPasswordData.email.trim())) {
        newErrors.emails = "Please enter a valid email address";
        isValid = false;

      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const loginUser = async (e) => {
    e.preventDefault();

    if (validateForm('login')) {
      setBtn(true);

      try {
        const result = await handleLogin(formData);
        console.log(result, 'result');

        if (result.data && result.data.data.token) {
          localStorageData.set('token', result.data.data.token);
          dispatch(setUserData(result.data.data.user));
          onLogin();
          setBtn(false);
        }

        setBtn(false);
        showToastMessage('success', result.data.message);

        if (result?.data?.data?.user?.role === 'manager') {
          navigate('/staff');
        } else {
          navigate('/');
        }
      } catch (error) {
        setBtn(false);
        console.log(error,'errrrorrrr');

        // Use toast for error notification
        if (error.message) {
          showToastMessage('error', error.message)
        } else {
          showToastMessage('error','An unexpected error occurred.');
        }
      }
    }
  };


  const forgetPassword = async (e) => {
    e.preventDefault();

    console.log(validateForm('forgetpassword'), 'forgetpassword');

    if (validateForm('forgetpassword')) {

      setBtn(true)

      try {
        const result = await handleForgetPassword(forgetPasswordData);

        if (result.data && result.data.data.token) {
          localStorageData.set('token', result.data.data.token)
          // localStorageData.set('authUser', result.data.data.user)
        }
        setBtn(false)
        showToastMessage('success', result.data.message)

      } catch (error) {
        setBtn(false)
        console.log(error.response.data.message);
        showToastMessage('error', error.response.data.message)
      }

    }

  };

  const toggleForgetPassword = () => {
    setShowForgetPassword(!showForgetPassword);
  };



  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper auth">
            <div className="auth-content">
              <div className="left-img"></div>
              <div className="auth-form-light">
                <div className="auth-form-content">
                  <div className="brand-logo">
                    <img src={ReactLogo} alt="logo" />
                  </div>
                  {showForgetPassword ? (
                    <div>
                      <h1>Reset Password</h1>
                      <h4>Enter Your Email or Username</h4>
                      <form className="auth-form" >
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="forgetPasswordEmail"
                            value={forgetPasswordData.email}
                            onChange={(e) => handleInputChange(e, 'forgetPassword')}
                            name="email"
                            placeholder="Enter your email"
                          />
                          {errors.emails && (
                            <div className="text-danger">{errors.emails}</div>
                          )}
                        </div>
                        <div className="mt-3">
                          <button
                            type="button"
                            disabled={btn}
                            onClick={forgetPassword}
                            className="btn btn-block btn-gradient-danger btn-lg font-weight-medium auth-form-btn w-100"
                          // disabled={!isForgetPasswordFormValid}
                          >
                            Send Password Reset Link
                          </button>
                        </div>
                      </form>
                      <div className="my-2 d-flex justify-content-center align-items-center mt-3">
                        <div onClick={toggleForgetPassword} className="auth-link text-black">
                          Back to login
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <h1>Welcome</h1>
                      <h4>Login into your Account.</h4>
                      <form className="auth-form" >
                        <div className="form-group login-radio">
                          <label className="form-label">Login Using:</label>
                          <div className="form-radio-group">
                            <div className="form-radio form-radio-box">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="basic"
                                name="loginType"
                                value="0"
                                checked={formData.loginType == 0}
                                onChange={
                                  (e) => {
                                    handleShowForm(e.target.value)
                                    handleInputChange(e, 'login')
                                  }
                                }
                              />
                              <label htmlFor="basic" className="form-check-label">
                                <span class="radio">
                                  <i class="mdi mdi-check menu-icon"></i>
                                </span>
                                Mobile Number
                              </label>
                            </div>
                            <div className="form-radio form-radio-box">
                              <input
                                type="radio"
                                className="form-check-input"
                                id="premium"
                                name="loginType"
                                value="1"
                                checked={formData.loginType == 1}
                                onChange={
                                  (e) => {
                                    handleShowForm(e.target.value)
                                    handleInputChange(e, 'login')
                                  }
                                }
                              />
                              <label htmlFor="premium" className="form-check-label">
                                <span class="radio">
                                  <i class="mdi mdi-check menu-icon"></i>
                                </span>
                                Email Address
                              </label>
                            </div>
                          </div>
                        </div>

                        {(showForm == 1) ? (

                          <div className="form-group">
                            <label>Email Address</label>
                            <input
                              type="email"
                              className="form-control form-control-lg"
                              id="exampleInputEmail1"
                              value={formData.email}
                              onChange={(e) => handleInputChange(e, 'login')}
                              name="email"
                              placeholder="Enter Email"
                            />
                            {errors.email && (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          </div>
                        ) : (

                          <div className="form-group">
                            <label>Mobile Number</label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="exampleInputEmail1"
                              value={formData.phone_number}
                              onKeyDown={(e) => validatePhone(e)}
                              onChange={(e) => handleInputChange(e, 'login')}
                              name="phone_number"
                              placeholder="Enter mobile number"
                            />
                            {errors.phone_number && (
                              <div className="text-danger">{errors.phone_number}</div>
                            )}
                          </div>

                        )}
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="exampleInputPassword1"
                            value={formData.password}
                            onChange={(e) => handleInputChange(e, 'login')}
                            name="password"
                            placeholder="Enter Password"
                          />
                          {errors.password && (
                            <div className="text-danger">{errors.password}</div>
                          )}
                        </div>
                        <div className="my-2 d-flex justify-content-between align-items-center">
                          <div onClick={toggleForgetPassword} className="auth-link text-black">
                            Forgot password?
                          </div>
                        </div>
                        <div className="mt-3">
                          <button
                            type="button"
                            disabled={btn}
                            onClick={loginUser}
                            className="btn btn-block btn-gradient-danger btn-lg font-weight-medium auth-form-btn w-100"
                          // disabled={!isLoginFormValid}
                          >
                            Sign In
                          </button>
                        </div>

                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

