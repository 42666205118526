import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import wrapModalfn from "../../../components/modal/modal";
import ConfirmationPopupButton from "../../../components/ConfirmationPopup/ConfirmationPopupButton";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";
import Loading from "../../../components/loader/Loading";
import IframeModal from "../../../components/modal/IframeModal";
import { showToastMessage } from "../../../helpers/toastr";
import DataTable from "react-data-table-component";
import { handleDeleteProductBilling, handleProductBillingListing, handleProductBillingPDF, handleGetProductBillingHistory } from "../../../services/productBillingService";
import { formatAmountSymbol } from "../../../helpers/formatAmountSymbol";
import { paymentOption } from "../../../helpers/paymentOption";
import ProductInvoice from "../../InvoicesPrint/ProductInvoice";
import { debounce } from 'lodash';
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission";
import SearchFilter from "../../../components/pagination/SearchFilter.jsx";
import showDeletePopup from "../../../helpers/showDeletePopup.js";
// import { handleBillingListing, handleBillingPDF, handleDeleteBilling } from "../../../services/billingService";
// import { showToastMessage } from "../../../helpers/toastr";
// import { formatDate } from "../../../helpers/formatDate";
// import { formatTime } from "../../../helpers/formatTime";
// import { formatAmountSymbol } from "../../../helpers/formatAmountSymbol";
// import Loading from "../../../components/loader/Loading";
// import ConfirmationPopupButton from "../../../components/ConfirmationPopup/ConfirmationPopupButton";
// import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";
// import IframeModal from "../../../components/modal/IframeModal";
// import wrapModalfn from "../../../components/modal/modal";
import { conditionalRowStyles } from "../../../helpers/conditionalRowStyles.js";
import { handleGetOtp } from "../../../services/billingService.js";
import showOtpVerficationPopup from "../../../helpers/showOtpVerficationPopup.js";
import { handleOtpVerfication } from "../../../services/billingService.js";
import showEditPopup from "../../../helpers/showEditPopup.js";
import ReasonTooltip from '../../customer/ReasonTooltip.jsx';

const ProductBillingListing = ({ selectGlobalOrg, filterData }) => {

    // console.log(filterData,'filterData');
    const userData = useSelector((state) => state.userData);
    const [popupID, setPopupID] = useState('');
    const [data, setData] = useState([]); // Use state to store the data
    const [loading, setLoading] = useState(true);
    const [pdfData, setPdfData] = useState([]);
    const [numPages, setNumPages] = useState(1);
    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;
    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };

    const columns = [
        {
            name: 'Bill No',
            selector: row => row?.bill_no,
            sortable: true,


        },
        {
            name: 'Name',
            selector: row => row.get_customer_info?.name,
            sortable: true,
            sortFunction: (a, b) => a?.get_customer_info?.name.localeCompare(b?.get_customer_info.name),

        },
        {
            name: 'Mobile Number',
            selector: row => row.get_customer_info?.phone_number,
            sortable: true,
        },
        // {
        //     name: 'Date',
        //     selector: row => row.date ? formatDate(row.date) : '',
        // },
        // {
        //     name: 'Time',
        //     selector: row => row.time ? formatTime(row.time) : '',
        // },
        {
            name: 'Payment Mode',
            selector: row => paymentOption(row.payment_mode),
            sortable: true,
            // selector: row => row && row.out_standing !== null ? row.out_standing : '0.00',
        },
        {
            name: 'Sold By',
            selector: row => row.get_multiple_products[0]?.product_added_by?.name || 'N/A',
            sortable: true,
        },

        {
            name: 'Grand Total',
            selector: row => row?.sub_total ? formatAmountSymbol(row?.sub_total) : formatAmountSymbol(0),
            sortable: true,
            sortFunction: (a, b) => parseFloat(a.sub_total) - parseFloat(b.sub_total),
            cell: row => formatAmountSymbol(row?.sub_total || '0.00'),
            // selector: row => row && row.out_standing !== null ? row.out_standing : '0.00',
        },
    ];

    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request ? row?.has_latest_delete_request.reason : '',
            sortable: true,
            cell: row => {
                if (row?.has_latest_delete_request) {
                    const reason = row.has_latest_delete_request.reason;
                    return <ReasonTooltip reason={reason} />;
                } else {
                    return '';
                }
            }
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update product-billing') && (
                    <button className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white" onClick={() => {
                        if (row.deleted_at !== null) {
                            showToastMessage('error', 'Record is deleted, you can not edit it.');
                        } else {
                            handleEdit(row.uuid, 'edit');
                        }
                    }}><i class="mdi mdi-lead-pencil menu-icon bodytext-24"></i></button>
                )
                }
                {checkPermission(userData, 'Delete product-billing') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is already deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.uuid);
                            }
                        }
                        }

                    />)}
                <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleDownload(row.uuid)}><i class="mdi mdi-eye menu-icon bodytext-24"></i></button>

                {/* <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleHistory(row.product_multiple_payments[0]?.product_billing_id)}>  <i class="mdi mdi-history menu-icon bodytext-24"></i></button> */}

                {/* <button className="icon-sm-medium round-100 bg-white icontext-red borderwidth-0" onClick={() => handleDelete(row.id)}><i class="mdi mdi-delete-empty menu-icon bodytext-24"></i></button> */}
                {/* Add more action buttons as needed */}
            </div >
        ),
    });



    const navigate = useNavigate();

    const handleEdit = async (uuid) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    navigate(`/edit-product-billing/${uuid}`, { state: { reason } });
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    navigate(`/edit-product-billing/${uuid}`, { state: { reason } });
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message);
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };
    const handleDelete = async (uuid) => {

        const reason = await showDeletePopup();

        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteProductBilling(uuid, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage)
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const requestType = 'delete';
            const result = await handleGetOtp(uuid, reason, requestType);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(uuid, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteProductBilling(uuid, reason);
                            fetchData(currentPage, perPage)
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message)
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Edit operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }

    }


    // handleBillingPDF

    const handleDownload = async (uuid, pdfType) => {


        try {
            const result = await handleProductBillingPDF(uuid);

            openModal();

            setPdfData(result?.data?.data);

            // const reader = new FileReader();
            // reader.readAsDataURL(result.data);
            // reader.onloadend = () => {
            //   const base64data = reader.result;
            //   setPdf(base64data);
            // };const pdf = await result.data.arrayBuffer();
            // const pdfData = new Uint8Array(pdf);
            // const pdfDoc = await pdfjsLib.getDocument({ data: pdfData }).promise;
            // setNumPages(pdfDoc.numPages);



        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }

    }

    const customSort = (rows, selector, direction) => {
        return orderBy(rows, selector, direction);
    };


    useEffect(() => {
        if (filterData != undefined) {
            const parsedFilterData = JSON.parse(filterData);
            if (parsedFilterData.length === 0) {
                return;
            }
        }
        // if (filterData != undefined) {
        //     const parsedFilterData = JSON.parse(filterData);
        //     if (Object.keys(parsedFilterData).length === 0) {
        //         return;
        //     }
        // }

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(filterData, 1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(filterData, 1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue, filterData]);


    const fetchData = async (filterData, page, per_page, searchText) => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, option => option.value);
        const status = undefined;

        // Check if filterData is empty or undefined/null and set it to undefined if true
        const updatedFilterData = (filterData && Object.keys(filterData).length === 0) ? undefined : filterData;

        try {
            const result = await handleProductBillingListing(initialOrgIds, status, updatedFilterData, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data);
            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        } finally {
            setLoading(false);
        }
    };






    const iframeHeight = `${numPages * 700}px`;

    return (
        <>
            <ConfirmationPopup
                handleDelete={handleDelete}
                popupID={popupID}
                setPopupID={setPopupID}
            />
            <div className="card">
                <div className="card-header bg-transparent">
                    <div className="row align-items-center">
                        <div class="col">
                            <h4 class="card-title heading-h3 mb-0">Product Listing</h4>
                        </div>
                        <div class="col-auto">

                            {checkPermission(userData, 'Create product-billing') && (
                                <NavLink to="/product-billing" className="btn button-primary btn-icon-left modalButtonCommon">
                                    <span class="icon">
                                        <i class="fa fa-plus-circle"></i>
                                    </span>
                                    New Product Billing
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <DataTable
                                columns={columns}
                                data={data?.data}
                                pagination
                                paginationPerPage={25}
                                paginationServer
                                paginationTotalRows={data?.total}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                subHeader
                                subHeaderComponent={<SearchFilter setSearchValue={setSearchValue} searchValue={searchValue} />}
                                paginationComponentOptions={{
                                    rangeSeparatorText: 'of',
                                    noRowsPerPage: true,
                                }}
                                {...(hasAdminRole && { conditionalRowStyles })}
                            />
                        </>
                    )}
                </div>
            </div>
            <IframeModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                closeModal={closeModal}
                customStyles={customStyles}
                layout={<ProductInvoice data={pdfData} />}
            // pdf = {pdf}
            // iframeHeight={iframeHeight}
            />
        </>
    )


}

export default ProductBillingListing;