import { formatAmountSymbol } from "../../helpers/formatAmountSymbol"

export const CustomerDetail = ({ selectedCustomer, redeemServices }) => {
    const serviceNamesArray = selectedCustomer.serviceNames
        ? Object.values(selectedCustomer.serviceNames)
        : [];
    const packageDetails = selectedCustomer?.packageDetails || [];


    return <>

        <div className="customer_detail_header">
            <h5 class="bodytext-20 fontweight-700 text-dark ">Customer Detail</h5>
        </div>
        <address className="text-primary">
            <div className="customer_detail_body">
                <div className="customer_detail_wrap">
                    <p className=""> Customer Name </p>
                    <p className="cust_name">{selectedCustomer?.name || '-----------'}</p>
                </div>
                <div className="customer_detail_wrap">
                    <p className=""> Customer Wallet </p>
                    <p className="cust_wallet">{selectedCustomer?.wallet ? formatAmountSymbol(selectedCustomer?.wallet) : formatAmountSymbol(0)}</p>
                </div>
                <div className="customer_detail_wrap">
                    <p className=""> Customer Outstanding </p>

                    <p className="cust_outstanding">
                        {selectedCustomer?.out_standing
                            ? formatAmountSymbol(Math.abs(selectedCustomer.out_standing))
                            : selectedCustomer?.get_user_subscription?.length
                                ? formatAmountSymbol(
                                    Math.abs(
                                        selectedCustomer.get_user_subscription[
                                            selectedCustomer.get_user_subscription.length - 1
                                        ].outstanding_amount || 0
                                    )
                                )
                                : formatAmountSymbol(0)}
                    </p>

                </div>
       
                {packageDetails.length > 0 && (
                    <div className="customer_detail_wrap">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Service Name</th>
                                    <th>Services Left</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageDetails.map((pkg, index) => (
                                    <tr key={index}>
                                        <td>{pkg.packageName}</td>
                                        <td>{pkg.servicesLeft}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {selectedCustomer.advance_packages &&
                    selectedCustomer.advance_packages.length > 0 &&
                    selectedCustomer.advance_packages.some(
                        (pkg) => pkg.services && pkg.services.length > 0
                    ) && (
                        <div className="customer_detail_wrap">
                            <button
                                type="button"
                                id="redeem_package"
                                onClick={redeemServices}
                                className="btn btn-primary btn-icon-left"
                            >
                                Redeem Package
                            </button>
                        </div>
                    )}

            </div>

        </address>

    </>


}