import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";

// import logo from './logo.svg';
import Sidebar from "./partials/Sidebar.jsx";
import Navbar from "./partials/Navbar.jsx";
import Footer from "./partials/Footer";
import Dashboard from "./pages/Dashboard";
import Customer from './pages/customer/Customer.jsx'
import Service from './pages/services/service/Service.jsx'
import ServiceCategory from './pages/services/serviceCategory/ServiceCategory.jsx'
import { Login } from "./pages/Login/Login.jsx";
import { localStorageData } from "./helpers/utils.js"
import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'primeicons/primeicons.css';

import { ResetPassword } from "./pages/Login/ResetPassword.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from './actions/dataAction.js';
import { handleUserProfile } from './services/userProfile.js'
import Staff from "./pages/staff/Staff.jsx";
import Billing from "./pages/appointments/billing/Billing.jsx";
import BillingListing from "./pages/appointments/billing/BillingListing.jsx";
import EditBilling from "./pages/appointments/billing/EditBilling.jsx";

import SellMembership from "./pages/SellMembership/SellMembership.jsx";
import Appointment from "./pages/appointments/Appointment/Appointment.jsx";
import AppointmentListing from "./pages/appointments/Appointment/AppointmentListing.jsx";
import EditAppointment from "./pages/appointments/Appointment/EditAppointment.jsx";
import ExpenseCategory from "./pages/Expenses/ExpenseCategory.jsx";
import Expenses from "./pages/Expenses/Expenses.jsx";
import MembershipListing from "./pages/SellMembership/MembershipListing.jsx";
import ProductBrand from "./pages/Product/ProductBrand/ProductBrand.jsx";
import Product from "./pages/Product/Product/Product.jsx";
import ProductBilling from "./pages/Product/ProductBilling/ProductBilling.jsx";
import ProductBillingListing from "./pages/Product/ProductBilling/ProductBillingListing.jsx";
import EditProductBilling from "./pages/Product/ProductBilling/EditProductBilling.jsx";
import Profile from "./pages/Profile/Profile.jsx";
import Permission from "./pages/staff/Permission.jsx";
import Error403 from "./pages/Errors/Error403.jsx";
import BillingInvoice from "./pages/InvoicesPrint/BillingInvoice.jsx";
import Setting from "./pages/Settings/Setting.jsx";
import Package from "./pages/Packages/Package.jsx";
import AdvancePackage from "./pages/Packages/AdvancePackage.jsx";
import AdvancePackageListing from "./pages/Packages/AdvancePackageListing.jsx";
import EditAdvancePackage from "./pages/Packages/EditAdvancePacakge.jsx";
import StaffReport from "./pages/Report/Staff/StaffReport.jsx";
import SalesReport from "./pages/Report/Sales/SalesReport.jsx";
import OverviewReport from "./pages/Report/Overview/OverviewReport.jsx"
import StaffPerfomance from "./pages/staff/StaffPerfomance.jsx";




function App() {

  // const location = useLocation();

  const [isLoggedIn, setLoggedIn] = useState('');

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    const token = localStorageData.get('token');
    const fetchData = async () => {
      if (token) {
        try {
          const result = await handleUserProfile(token);
          if (result.data) {
            dispatch(setUserData(result?.data?.data?.user));
            setLoggedIn(true);
          }
        } catch (error) {
          console.log(error);
          setLoggedIn(false);
          localStorageData.remove('token')
          window.location.href = "/";
        }
      } else {
        setLoggedIn(false);
      }
    };

    fetchData();
  }, []);



  const [selectGlobalOrg, setSelectGlobalOrg] = useState(null);

  const handleOrgChange = (event) => {
    const selectedValue = event.target.value;
    localStorageData.set('organisation', selectedValue)
    setSelectGlobalOrg(selectedValue);
  }


  const pageData = "";

  return (

    <Router history={History}>

      <div className="App">
        <ToastContainer />

        {isLoggedIn === false ? (
          <Routes>
            <Route index path="/" element={!localStorageData.get('token') ? <Login onLogin={() => setLoggedIn(true)} /> : <Navigate to="/" />} />
            <Route path="/reset-password" element={!localStorageData.get('token') ? <ResetPassword onLogin={() => setLoggedIn(true)} /> : <Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/invoice" element={<BillingInvoice data={pageData} />} />
          </Routes>
        ) : (
          isLoggedIn === true && (
            <div className="container-scroller">
              <Navbar handleOrgChange={handleOrgChange} />
              <div className="container-fluid page-body-wrapper">
                <Sidebar
                  setLoggedIn={setLoggedIn}
                />
                <div className="main-panel" onClick={() => {
                  if (document.body.classList.contains('sidebar-icon-only') && window.innerWidth <= 768) {
                    document.body.classList.remove('sidebar-icon-only');
                  }
                }}>
                  <div className="content-wrapper">
                    <Routes>
                      <Route path="/" element={<Dashboard selectGlobalOrg={selectGlobalOrg} />} />
                      <Route path="/customers" element={<Customer selectGlobalOrg={selectGlobalOrg} />} />
                      <Route path="/staff" element={<Staff selectGlobalOrg={selectGlobalOrg} />} />

                      <Route path="/staff-performance" element={<StaffPerfomance selectGlobalOrg={selectGlobalOrg}/>} />
                      <Route path="/permission" element={<Permission selectGlobalOrg={selectGlobalOrg} />} />
                      <Route path="/settings" element={<Setting selectGlobalOrg={selectGlobalOrg} />} />
                      <Route>
                        <Route index path="/package" element={<Package selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/advance-package" element={<AdvancePackage selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/advance-package-listing" element={<AdvancePackageListing selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/edit-advance-package/:id" element={<EditAdvancePackage selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>
                      <Route path="/unauthorised" element={<Error403 to="/unauthorised" />} />
                      <Route>
                        <Route index path="/service" element={<Service selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/service-category" element={<ServiceCategory selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>
                      <Route>
                        <Route index path="/expense" element={<Expenses selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/expense-category" element={<ExpenseCategory selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>
                      <Route>
                        <Route index path="/billing" element={<Billing selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/billing-listing" element={<BillingListing selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/edit-billing/:uuid" element={<EditBilling selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>

                      <Route>
                        <Route index path="/appointments" element={<Appointment selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/appointment-listing" element={<AppointmentListing selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/edit-appointment/:uuid" element={<EditAppointment selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>

                      <Route>
                        <Route index path="/product-brand" element={<ProductBrand selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/products" element={<Product selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/product-billing" element={<ProductBilling selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/product-billing-listing" element={<ProductBillingListing selectGlobalOrg={selectGlobalOrg} />} />
                        <Route path="/edit-product-billing/:uuid" element={<EditProductBilling selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>

                      <Route>
                        <Route index path="/sell-membership" element={<SellMembership selectGlobalOrg={selectGlobalOrg} />} />
                        <Route index path="/membership-listing" element={<MembershipListing selectGlobalOrg={selectGlobalOrg} />} />

                        {/* <Route  path="/appointment-listing" element={<BillingListing selectGlobalOrg={selectGlobalOrg} />} />
                      <Route  path="/edit-billing/:uuid" element={<EditBilling selectGlobalOrg={selectGlobalOrg} />} /> */}
                      </Route>

                      <Route>
                        <Route index path="/profile" element={<Profile selectGlobalOrg={selectGlobalOrg} />} />
                      </Route>
                      <Route path="/staff-report" element={<StaffReport selectGlobalOrg={selectGlobalOrg} />} />
                      <Route path="/sales-report" element={<SalesReport selectGlobalOrg={selectGlobalOrg} />} />
                      <Route path="/over-view/report" element={<OverviewReport selectGlobalOrg={selectGlobalOrg} />} />






                      {/* <Route path="*" element={<Navigate to="/" />} /> */}
                    </Routes>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          )
        )}
        {/* {!hasAdminRole && !hasCoOwnerRole ? (
  <div>
    {console.log(hasAdminRole,hasCoOwnerRole)}
    <Routes>
      <Route index path="/unauthorised" element={<Error403 to="/unauthorised" />} />
    </Routes>
  </div>
) : null} */}

      </div>
    </Router>


  );

}

export default App;
