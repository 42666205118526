// Define global array
var paymentOptions = [
    { value: "cash", name: "Cash" },
    { value: "cc", name: "Card" },
    { value: "pkg", name: "Package" },
    { value: "gpay", name: "Gpay/UPI" },
    { value: "upibills", name: "API" }
];


// Function to return name based on value
// export function paymentOption(value) {
//     for (var i = 0; i < paymentOptions.length; i++) {
//         if (paymentOptions[i].value === value) {
//             return paymentOptions[i].name;
//         }
//     }
//     return null; 
// }

export function paymentOption(value) {
    // Ensure value is a string before calling split
    if (typeof value !== 'string') {
        return null;  // or you can return an empty string, depending on how you want to handle it
    }

    const values = value.split(',').map(item => item.trim());
    const matchedNames = [];

    // Iterate over the values to find matching names
    for (let i = 0; i < values.length; i++) {
        const currentValue = values[i];

        // Check against all paymentOptions
        for (let j = 0; j < paymentOptions.length; j++) {
            if (paymentOptions[j].value === currentValue) {
                matchedNames.push(paymentOptions[j].name); // Use push to allow duplicates
                break; // Exit inner loop once a match is found to avoid unnecessary checks
            }
        }
    }

    // Convert array to a comma-separated string or null if none found
    return matchedNames.length > 0 ? matchedNames.join(', ') : null;
}



export function selectOptions(type) {

    const array = type === 'deleteOption' ? paymentOptions.filter((option) => option.value !== 'pkg') : paymentOptions;

    return array.map((option) => (
        <option key={option.value} value={option.value}>
            {option.name}
        </option>
    ));
}
