import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import Select from 'react-select';

export const PackageForm = ({ services, onChange, errors, packagedData }) => {
    const [packages, setPackages] = useState([
        { packageName: "", serviceRows: [{ serviceId: "", service: "", amount: 0, quantity: 1, total: 0 }] },
    ]);
    console.log(packagedData, 'packagedData');

    // Debounce the onChange callback
    const debouncedOnChange = debounce((updatedPackages) => {
        onChange(updatedPackages);
    }, 300);

    useEffect(() => {
        if (packagedData && packagedData.length > 0) {
            setPackages(
                packagedData.map((pkg) => ({
                    ...pkg,
                    serviceRows: pkg.serviceRows || [], // Ensure serviceRows is always an array
                }))
            );
        }
    }, [packagedData]);

    const addServiceRow = (pkgIndex) => {
        setPackages((prev) =>
            prev.map((pkg, index) =>
                index === pkgIndex
                    ? {
                        ...pkg,
                        serviceRows: [
                            ...(pkg.serviceRows || []),
                            {
                                serviceId: "",
                                service: "",
                                amount: 0,
                                quantity: 1,
                                total: 0,
                            },
                        ],
                    }
                    : pkg
            )
        );
    };

    const updatePackageRow = (pkgIndex, rowIndex, updatedRow) => {
        setPackages((prev) =>
            prev.map((pkg, i) =>
                i === pkgIndex
                    ? {
                        ...pkg,
                        serviceRows: Array.isArray(pkg.serviceRows)
                            ? pkg.serviceRows.map((row, j) =>
                                j === rowIndex ? { ...row, ...updatedRow } : row
                            )
                            : [], // Ensure serviceRows is always an array
                    }
                    : pkg
            )
        );
    };

    const handleServiceChange = (pkgIndex, rowIndex, selectedOption) => {
        if (!selectedOption) return;

        const { id: serviceId, price } = selectedOption;
        const row = packages[pkgIndex].serviceRows[rowIndex];
        updatePackageRow(pkgIndex, rowIndex, {
            serviceId,
            service: selectedOption.label,
            amount: price || 0,
            total: price ? price * row.quantity : 0,
        });
    };

    const handleAmountChange = (pkgIndex, rowIndex, amount) => {
        const sanitizedAmount = parseFloat(amount) || 0;
        const row = packages[pkgIndex].serviceRows[rowIndex];
        updatePackageRow(pkgIndex, rowIndex, {
            amount: sanitizedAmount,
            total: sanitizedAmount * (row.quantity || 1),
        });
    };

    const handleQuantityChange = (pkgIndex, rowIndex, quantity) => {
        const sanitizedQuantity = parseInt(quantity, 10) || 1;
        const row = packages[pkgIndex].serviceRows[rowIndex];
        updatePackageRow(pkgIndex, rowIndex, {
            quantity: sanitizedQuantity,
            total: sanitizedQuantity * (row.amount || 0),
        });
    };

    const removeRow = (pkgIndex, rowIndex) => {
        setPackages((prev) => {
            const updatedPackages = prev.map((pkg, i) =>
                i === pkgIndex
                    ? {
                        ...pkg,
                        serviceRows: pkg.serviceRows.filter((_, j) => j !== rowIndex),
                    }
                    : pkg
            );
            console.log('Updated Packagesfdsafsd:', updatedPackages); // Debugging
            return updatedPackages;
        });
    };

    const handleUpdateServices = () => {
        const sanitizedData = packages.map((pkg) => ({
            packageName: pkg.packageName,
            services: Array.isArray(pkg.serviceRows)
                ? pkg.serviceRows.map((row) => ({
                    serviceId: row.serviceId,
                    quantity: row.quantity,
                    amount: row.amount,
                    total: row.total,
                }))
                : [],
        }));

        const mergedData = packagedData
            ? packagedData.map((existingPkg, index) => ({
                ...existingPkg,
                ...sanitizedData[index],
            }))
            : sanitizedData;

        setPackages(prevPackages => {
            return prevPackages.map((pkg, index) => ({
                ...pkg,
                serviceRows: mergedData[index]?.services || [],
            }));
        });

        // Ensure that `mergedData` only includes services that are present
        if (mergedData.some(pkg => pkg.services.length > 0)) {
            debouncedOnChange(mergedData);
        }
    };

    return (
        <div className="col-md-12">
            <div className="package-form">
                {packages.map((pkg, pkgIndex) => (
                    <div key={pkgIndex} className="py-4 lg:pr-3 xl:pr-0">
                        <div>
                            <div className="d-flex align-items-center gap-4 flex-wrap mb-4">
                                <h2 className="card-title heading-h3 text-black mb-0 flex-auto fontweight-600 bodytext-20">Advance Package</h2>
                                  <div className="d-flex gap-2 align-items-center flex-wrap">
                                    <button
                                        type="button"
                                        className="btn btn-primary button-icon-left"
                                        onClick={() => addServiceRow(pkgIndex)}
                                    >
                                    <i class="fa fa-plus-circle"></i>
                                    Add Service
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleUpdateServices}
                                    >
                                        Confirm Services
                                    </button>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Package Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="packageName"
                                    placeholder="Package Name"
                                    value={pkg.packageName}
                                    onChange={(e) =>
                                        setPackages((prev) =>
                                            prev.map((p, i) =>
                                                i === pkgIndex ? { ...p, packageName: e.target.value } : p
                                            )
                                        )
                                    }
                                />
                                {errors.packageName && (
                                    <div className="text-danger">{errors.packageName}</div>
                                )}
                            </div>
                            {pkg.serviceRows && pkg.serviceRows.map((row, rowIndex) => (
                                <div key={rowIndex} className="row mb-3">
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                         <div className="form-group">
                                            <label>Service</label>
                                            <Select
                                                name={`service-${pkgIndex}-${rowIndex}`}
                                                value={
                                                    services
                                                        .map((service) => ({
                                                            id: service.id,
                                                            label: service.name,
                                                            price: service.price
                                                        }))
                                                        .find((option) => option.id === row.serviceId)
                                                }
                                                options={services.map((service) => ({
                                                    id: service.id,
                                                    label: service.name,
                                                    price: service.price
                                                }))}
                                                placeholder={'Select Service'}
                                                isSearchable={true}
                                                classNamePrefix="form"
                                                className="form-control form-select"
                                                onChange={(selectedOption) => handleServiceChange(pkgIndex, rowIndex, selectedOption)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                         <div className="form-group">
                                            <label>Amount</label>
                                            <input
                                                type="number"
                                                className="form-control mb-0"
                                                placeholder="Amount"
                                                value={row.amount}
                                                onChange={(e) =>
                                                    handleAmountChange(pkgIndex, rowIndex, e.target.value)
                                                }
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Quantity</label>
                                            <input
                                                type="number"
                                                className="form-control mb-0"
                                                placeholder="Quantity"
                                                value={row.quantity}
                                                min={1}
                                                onChange={(e) =>
                                                    handleQuantityChange(pkgIndex, rowIndex, e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-2">
                                        <div className="form-group">
                                            <label>Total</label>
                                            <input
                                                type="number"
                                                className="form-control mb-0"
                                                value={row.total || ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-1 col-md-1 d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => removeRow(pkgIndex, rowIndex)}
                                        >
                                            <i className="mdi mdi-delete-empty"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
