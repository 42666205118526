import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { showToastMessage } from '../../helpers/toastr';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol';
import { handleOutStandingBalance } from '../../services/customerServices';
import { selectOptions } from '../../helpers/paymentOption';

Modal.setAppElement('#root');

const EditOutStanding = ({ modalIsOpen, closeModal, customStyles, hisData }) => {
    const [formData, setFormData] = useState({
        paid_outstanding: '',
    });
    const [error, setError] = useState('');
    const [btn, setBtn] = useState(false);
    const [fields, setFields] = useState([{ selectValue: "", inputValue: "" }]);
    const [errors, setErrors] = useState({});
    const [isVisible, setIsVisible] = useState([false]);

    const outstandingAmount = Math.abs(hisData?.out_standing || 0);

    useEffect(() => {
        setError('');
    }, [hisData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = parseFloat(value) || 0;

        if (numericValue > outstandingAmount) {
            setError(`You cannot add more than ${formatAmountSymbol(outstandingAmount)}`);
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: outstandingAmount,
            }));
        } else {
            setError('');
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: numericValue,
            }));
        }
    };

    const handleSelectChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].selectValue = e.target.value;

        if (!e.target.value) {
            updatedFields[index].inputValue = "";
        }

        setFields(updatedFields);

        const newIsVisible = [...isVisible];
        newIsVisible[index] = !!e.target.value;
        setIsVisible(newIsVisible);
    };

    const handleMultiInputChange = (e, index) => {
        const updatedFields = [...fields];
        updatedFields[index].inputValue = e.target.value;
        setFields(updatedFields);
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;
    
        // Ensure the value is a string
        const paidOutstanding = formData.paid_outstanding ? String(formData.paid_outstanding).trim() : '';
    
        if (!paidOutstanding) {
            newErrors.paid_outstanding = "Outstanding amount is required";
            isValid = false;
        } else if (parseFloat(paidOutstanding) <= 0) {
            newErrors.paid_outstanding = "Outstanding amount must be greater than zero";
            isValid = false;
        } else if (parseFloat(paidOutstanding) > outstandingAmount) {
            newErrors.paid_outstanding = `Outstanding amount cannot exceed ${formatAmountSymbol(outstandingAmount)}`;
            isValid = false;
        }
    
        const totalPaymentMethodAmount = fields.reduce((sum, field) => sum + parseFloat(field.inputValue || 0), 0);
        if (totalPaymentMethodAmount !== parseFloat(paidOutstanding)) {
            newErrors.multiplePaymentMethods = `Total payment methods must equal ${paidOutstanding}`;
            isValid = false;
        }
    
        fields.forEach((field, index) => {
            if (!field.selectValue) {
                newErrors[`paymentMode_${index}`] = "Payment mode is required";
                isValid = false;
            }
        });
    
        setErrors(newErrors);
        return isValid;
    };
    

   const handleSubmit = async () => {
  if (!validateForm()) return;

  setBtn(true);

  // Prepare the form data with payment methods
  const payload = {
    ...formData,
    paymentMethods: fields.map((field) => ({
      method: field.selectValue,
      amount: field.inputValue,
    })),
  };

  try {
    const result = await handleOutStandingBalance(
      hisData.org_id,
      payload.paid_outstanding,
      hisData.uuid,
      payload.paymentMethods // Sending payment methods as part of the request
    );
    showToastMessage('success', result.data.message);
    closeModal();
    window.location.reload();
  } catch (error) {
    setBtn(false);
    const errorMessage = error?.response?.data?.message || "An unexpected error occurred.";
    showToastMessage('error', errorMessage);
  }
};

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add Outstanding Amount"
            className="custom-modal round-15 bg-white"
        >
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="mb-0 heading-h4 fontweight-500">Add Outstanding Amount</h2>
                <button
                    className="btn icon-md round-100 bodytext-22 border-0 icontext-darkgrey"
                    onClick={closeModal}
                >
                    <i className="mdi mdi-close menu-icon"></i>
                </button>
            </div>
            <div className="card-body">
                <form id="billingform" autoComplete="off">
                    <div className="col-md-12 mt-4">
                        <div className="form-group">
                            <label>Outstanding Amount:</label>
                            <input
                                type="number"
                                className="form-control"
                                name="paid_outstanding"
                                value={formData.paid_outstanding}
                                onChange={handleInputChange}
                                min="0"
                            />
                            {error && <p className="text-danger">{error}</p>}
                            {errors.paid_outstanding && <p className="text-danger">{errors.paid_outstanding}</p>}
                        </div>

                        <div className="form-group">
                            <label>Payment Mode:</label>
                            {fields.map((field, index) => (
                                <div key={index} className="row mb-2">
                                    <div className="col">
                                        <select
                                            className="form-select form-control"
                                            value={field.selectValue}
                                            onChange={(e) => handleSelectChange(e, index)}
                                        >
                                            <option value="">Select Payment Mode</option>
                                            {selectOptions('deleteOption')}
                                        </select>
                                        {errors[`paymentMode_${index}`] && (
                                            <p className="text-danger">{errors[`paymentMode_${index}`]}</p>
                                        )}
                                    </div>
                                    {isVisible[index] && (
                                        <div className="col">
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={field.inputValue}
                                                onChange={(e) => handleMultiInputChange(e, index)}
                                            />
                                        </div>
                                    )}
                                    <div className="col-auto">
                                        {fields.length > 1 && (
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => {
                                                    const updatedFields = fields.filter((_, i) => i !== index);
                                                    setFields(updatedFields);
                                                }}
                                            >
                                                -
                                            </button>
                                        )}
                                        {index === fields.length - 1 && (
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() =>
                                                    setFields([...fields, { selectValue: "", inputValue: "" }])
                                                }
                                            >
                                                +
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {errors.multiplePaymentMethods && (
                                <p className="text-danger">{errors.multiplePaymentMethods}</p>
                            )}
                        </div>

                        <div className="text-right mt-4">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                disabled={btn || !!error}
                                className="btn btn-primary"
                            >
                                {btn ? "Submitting..." : "Add Outstanding Balance"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default EditOutStanding;
