import { formatDate } from "../../helpers/formatDate";
import { getCurrentDate } from "../../helpers/getCurrentDate";
import styles from "./styles";
import ReactLogo from '../../assets/images/HairRaiserz.png'
import ReactLogo2 from '../../assets/images/HairRaiserz2.png'
import { useState } from "react";
import { useEffect } from "react";
import { localStorageData } from "../../helpers/utils";

const JobCard = ({ data, selectGlobalOrg, }) => {
  const organisationNames = {
    2: "Salon Legends",
    3: "Salon Legends Partner",
    4: "Tress Lounge TDI",
    6: "Sunny Enclave - Hair Raiserz",
    7: "Luxe 20 - Panchkula - Hair Raiserz",
    8: "Luxe-5, Hair Raiserz, Phase 5",
    9: "Hair Raiserz Luxe 40",
    10: "Hair Raiserz Luxe Phase 11",
  };

  // Get organisation name based on selectGlobalOrg, default to an empty string if not found
  

  const storedOrgId = localStorageData.get('organisation');
  const organisationName = organisationNames[storedOrgId] || "Unknown Organisation";


  // Determine if Tress Lounge condition is met
  const isTressLounge = storedOrgId
    ? ["4", "1"].includes(storedOrgId)
    : userData?.name == "Tress Lounge";

  // Set appropriate logo source
  const logoSrc = isTressLounge ? ReactLogo : ReactLogo2;

  // const logoSrc = isTressLounge ? ReactLogo : ReactLogo2;




  const services = [
    "Ironing", "Hair Cut", "Hair Colour", "Hair Spa", "Head Massage",
    "Threading", "Bleach", "Cleansing", "Facial", "Manicure",
    "Pedicure", "Waxing", "Shave/ BT", "Party Makeup", "Hair Do",
    "Engagement", "Bridal", "Reception"
  ];

  const loopItrate = new Array(25).fill(null);
  const date = getCurrentDate();

  return (
    <div className="invoice hr-job-card" style={styles.invoice}>
      <table style={styles.width}>
        <tbody>
          <tr>
            <td>
              <table style={styles.width}>
                <tbody>
                  <tr>
                    <td style={styles.textCenter}>
                      <img src={logoSrc} alt="Organization Logo" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
              <table style={styles.width}>
                <tbody>
                  <tr>
                    <td><p className="billno" style={styles.billno}>Bill No:</p></td>
                  </tr>
                  <tr>
                    <td><p className="billno" style={styles.billno}>Client Name:</p></td>
                  </tr>
                  <tr>
                    <td><p className="billno" style={styles.billno}>Phone Number:</p></td>
                  </tr>
                  <tr>
                    <td style={{ width: '40%' }}>
                      <p className="billno" style={styles.billno}>Date of Visit: {formatDate(date)}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table className="invoice-table" style={styles.width}>
                <thead className="theadbg" style={styles.theadbg}>
                  <tr>
                    <td className="theadtd" style={{ width: "3.33%", ...styles.theadtd }}></td>
                    <th className="theadth" style={{ width: "32.33%", ...styles.theadth }}>Service</th>
                    <th className="theadth" style={{ width: "32.33%", ...styles.theadth, ...styles.textLeft }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {loopItrate.slice(0, services.length).map((_, index) => (
                    <tr key={index} style={styles.borderb}>
                      <td className="theadtd">{index + 1}</td>
                      <td className="theadtd">{services[index]}</td>
                      <td className="theadtd"></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p style={{ textAlign: 'left', ...styles.theadth }}>
                We are fully satisfied with the services offered and have no complaints.
                {organisationName ?? " Tress Lounge"} shall not be responsible for any
              </p>
              <div className="bottom-sign">
                <p className="billno" style={styles.billno}>Client Name:</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default JobCard;
