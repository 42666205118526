import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSpeedometer from "react-d3-speedometer";
import { handleStaffListing, getEmployeePerformance } from '../../services/staffServices';
import { showToastMessage } from '../../helpers/toastr';
import DataTable from 'react-data-table-component';

const StaffPerformance = ({ selectGlobalOrg }) => {
    const userData = useSelector((state) => state.userData);
    const [selection, setSelection] = useState("Yesterday");
    const [dateInputValue, setDateInputValue] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [employeeListing, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [performanceData, setPerformanceData] = useState(null);

    // Fetch users whenever selection or selectGlobalOrg changes
    useEffect(() => {
        fetchUsers();
    }, [selectGlobalOrg]);

    useEffect(() => {
        if (
            (selection === "Date" && !dateInputValue) ||
            (selection === "CustomDate" && (!startDate || !endDate))
        ) {
            return;
        }

        if (selectedUser) {
            fetchPerformanceData();
        }
    }, [selectGlobalOrg, selection, dateInputValue, startDate, endDate, selectedUser]);


    const fetchUsers = async () => {
        setLoading(true);
        try {
            const initialOrgIds = Array.from(
                document.getElementById('orgId').selectedOptions,
                (option) => option.value
            );

            const result = await handleStaffListing(initialOrgIds, "", 1, 1, 20, "");
            const employees = result.data.data.data;

            const filteredEmployees = employees.filter(
                (user) => user.role?.role === "employee" || user.role?.role === "manager"
            );

            setUsers(filteredEmployees);

            setSelectedUser(null);
            const firstEmployee = filteredEmployees[0];
            setSelectedUser(firstEmployee || null);
        } catch (error) {
            console.error(error);
            showToastMessage('error', error.response?.data?.message || "Failed to fetch users");
        } finally {
            setLoading(false);
        }
    };



    const fetchPerformanceData = async () => {
        setLoading(true);
        try {
            const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
            const role = selectedUser?.role?.role || '';
            const userId = selectedUser?.id;
            const formData = {
                filterType: selection,
                filterPeriod: dateInputValue,
                startDate: startDate,
                endDate: endDate,
            };
            const result = await getEmployeePerformance(initialOrgIds, formData, userId, role);
            setPerformanceData(result?.data);
        } catch (error) {
            console.error(error);
            showToastMessage("error", error.message || "Failed to fetch performance data");
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            name: 'Total Customers',
            selector: row => row.customerAttendedCount,
            sortable: true,
        },
        {
            name: 'Service Bill',
            selector: row => row.totalServiceSales,
            sortable: true,
        },
        {
            name: 'Product Sales',
            selector: row => row.totalProductSales,
            sortable: true,
        },
        {
            name: 'Package Sale',
            selector: row => row?.totalPackageSales,
            sortable: true,
        },
        {
            name: 'Total Tip',
            selector: row => row.totalTipAmount,
            sortable: true,
        },
        {
            name: 'Total Sale',
            selector: row => row?.totalOverallSales ?? 0,
            sortable: true,
        },
    ];
    const serviceSalesDataArray = performanceData?.serviceSalesData
        ? Object.entries(performanceData?.serviceSalesData).map(([serviceName, serviceBill]) => ({
            serviceName,
            serviceBill,
        }))
        : [];
    const serviceCategorySalesDataArray = performanceData?.serviceCategoryData
        ? Object.entries(performanceData?.serviceCategoryData).map(([CategoryName, CategoryBill]) => ({
            CategoryName,
            CategoryBill,
        }))
        : [];
    const serviceColumns = [
        {
            name: 'Service Name',
            selector: row => row.serviceName,
        },
        {
            name: 'Total Sale',
            selector: row => row.serviceBill,
            sortable: true,
        },
    ];
    const serviceCatgeoryColumns = [
        {
            name: 'Service Category Name',
            selector: row => row.CategoryName,
        },
        {
            name: 'Total Sale',
            selector: row => row.CategoryBill,
            sortable: true,
        },
    ];
    useEffect(() => {

        setSelectedUser(null);
        setPerformanceData(null);
        fetchUsers();
    }, [selectGlobalOrg]);

    const handleUserChange = (e) => {
        const userId = e.target.value;
        const user = employeeListing.find((u) => u.id === parseInt(userId));

        if (user && (user.role.role === "manager" || user.role.role === "employee")) {
            setSelectedUser(user);
        } else {
            showToastMessage('error', 'Selected user is neither a Manager nor an Employee.');
        }
    };

    const handleSelectionChange = (e) => {
        setSelection(e.target.value);
    };

    const handleDateInputChange = (e) => {
        if (selection === "Date" && e.target.value) {
            setDateInputValue(e.target.value);
        }
    };

    const handleStartDateChange = (e) => {
        if (selection === "CustomDate" && e.target.value) {
            setStartDate(e.target.value);
        }
    };

    const handleEndDateChange = (e) => {
        if (selection === "CustomDate" && e.target.value) {
            setEndDate(e.target.value);
        }
    };
    const calculateMaxValue = (value) => {
        const scale = 10000;
        return Math.ceil(value / scale) * scale * 1.2;
    };

    return (
        <div className="row">
            <div className="col-lg-12 grid-margin">
                <div className="card">
                    <div className="card-header bg-transparent">
                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="card-title heading-h3 mb-0">Employee Performance</h4>
                            </div>
                            <div className="col-auto">
                                <div className="card-filter-input">
                                    <div className="input-date">
                                        <select
                                            className="form-select"
                                            onChange={handleSelectionChange} value={selection}
                                        >
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">Yesterday</option>
                                            <option value="Date">Date</option>
                                            <option value="Last-7-Days">Last 7 Days</option>
                                            <option value="Last-14-Days">Last 14 Days</option>
                                            <option value="Last-30-Days">Last 30 Days</option>
                                            <option value="Overall">Overall</option>
                                            <option value="CustomDate">Custom Date</option>
                                        </select>
                                    </div>
                                    {selection === "CustomDate" ? (
                                        <>
                                            <div className="input-date">
                                                <input
                                                    type="date"
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="input-date">
                                                <input
                                                    type="date"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </>
                                    ) : selection === "Date" ? (
                                        <div className="input-date">
                                            <input
                                                type="date"
                                                value={dateInputValue}
                                                onChange={handleDateInputChange}
                                                className="form-control"
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="userDropdown" className="form-label">Select Employee:</label>
                            <select
                                id="userDropdown"
                                className="form-select bg-gray-200 rounded-xl"
                                onChange={handleUserChange}
                                value={selectedUser?.id || ""}
                                style={{
                                    width: 'fit-content',
                                    outline: 'none',
                                }}
                            >
                                {Array.isArray(employeeListing) &&
                                    employeeListing.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-12">
                                {selectedUser && (
                                    <div className='row'>
                                        <div className="col-md-3 col-12">
                                            <div className="form-group">
                                                <label htmlFor="name" className="form-label">Employee Name:</label>
                                                <input
                                                    type='text'
                                                    className="form-control"
                                                    id='name'
                                                    name='name'
                                                    value={selectedUser.name}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-12">
                                            <div className="form-group">
                                                <label htmlFor="joiningDate" className="form-label">Joining Date:</label>
                                                <input
                                                    type='date'
                                                    className="form-control"
                                                    id='joiningDate'
                                                    name='joiningDate'
                                                    value={selectedUser.joiningDate}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-12">
                                            <div className="form-group">
                                                <label htmlFor="salary" className="form-label">Salary:</label>
                                                <input
                                                    type='number'
                                                    className="form-control"
                                                    id='salary'
                                                    name='salary'
                                                    value={selectedUser.salary}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-12">
                                            <div className="form-group">
                                                <label htmlFor="target" className="form-label">Target:</label>
                                                <input
                                                    type='number'
                                                    className="form-control"
                                                    id='target'
                                                    name='target'
                                                    value={selectedUser.target ?? '100000'}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                            {performanceData && (
                                <div className=" col-md-4 col-12 text-center">
                                    <ReactSpeedometer
                                        maxValue={1200000}
                                        value={Math.min(performanceData.totalOverallSales || 0, 1200000)}
                                        needleColor="red"
                                        startColor="green"
                                        endColor="red"
                                        textColor="black"
                                        segments={4}
                                        customSegmentStops={[0, 300000, 600000, 900000, 1200000]}
                                        segmentColors={['#008000', '#32CD32', '#FFD700', '#FFA500', '#FF4500']}
                                        needleHeightRatio={0.7}
                                        minValue={0}

                                    />

                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {performanceData && (
                    <>
                        <div className="card my-4">
                            <div className="card-header bg-transparent">
                                <h4 className="card-title heading-h3 mb-0">Performance Details</h4>
                            </div>
                            <div className="card-body">
                                <DataTable
                                    columns={columns}
                                    data={performanceData ? [performanceData] : []}
                                    pagination
                                    responsive
                                    striped
                                />
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header bg-transparent">
                                <h4 className="card-title heading-h3 mb-0">Sales Details</h4>
                            </div>
                            <div className="card-body flex flex-wrap gap-4">
                                {/* Services Sale Table */}
                                <div className="flex-1 min-w-[300px]">
                                    <h5 className="mb-3 fontweight-600 bodytext-16 text-black">Services Sale</h5>
                                    <DataTable
                                        columns={serviceColumns}
                                        data={serviceSalesDataArray}
                                        pagination
                                        responsive
                                        striped
                                    />
                                </div>

                                {serviceCategorySalesDataArray && (
                                    <div className="flex-1 min-w-[300px]">
                                        <h5 className="mb-3 fontweight-600 bodytext-16 text-black">Service Categories Sale</h5>
                                        <DataTable
                                            columns={serviceCatgeoryColumns}
                                            data={serviceCategorySalesDataArray}
                                            pagination
                                            responsive
                                            striped
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>

    );
};

export default StaffPerformance;
