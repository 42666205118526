import { param } from "jquery";
import axiosInstance from "./axiosConfig";

const handleStaff = async (formData, reason) => {
  try {
    const response = await axiosInstance.post(`save-staff?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleStaffListing = async (formData, role, status, page, per_page, searchText) => {
  try {
    const response = await axiosInstance.get(`get-staff-listing?orgId=${formData}&role=${role}&status=${status}`, {
      params: {
        page: page,
        per_page: per_page,
        searchText: searchText,
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditStaff = async (uuid) => {
  try {
    const response = await axiosInstance.get(`edit-staff?uuid=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteStaff = async (uuid, reason) => {
  try {
    const response = await axiosInstance.get(`delete-staff?uuid=${uuid}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getEmployeePerformance = async (orgId,formData,userId,role) => {
  try {
    const { filterType, filterPeriod, startDate, endDate,} = formData;
 
    const response = await axiosInstance.get(`/employee-performance?orgId=${orgId}&userId=${userId}&role=${role}`, {
      params: {
        filterType: filterType,  
        filterPeriod: filterPeriod, 
        startDate: startDate,
        endDate: endDate
      }
    });

    return response.data;
  } catch (error) {
    throw new Error('Error fetching performance data');
  }
};








export {
  handleStaff,
  handleStaffListing,
  handleEditStaff,
  handleDeleteStaff,
  getEmployeePerformance,
};