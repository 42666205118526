import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { handleExpenseCategoryListing } from '../../services/expenseCategory';
import { showToastMessage } from '../../helpers/toastr';
import { handleExpense } from '../../services/expense';
import { getCurrentDate } from '../../helpers/getCurrentDate';
import { formatIntger } from '../../helpers/formatIntger';
// import { handleServiceCategoryListing } from '../../../services/serviceCategory.js';
// import { handleService } from '../../../services/service.js';
Modal.setAppElement('#root')


const ExpensesModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen, selectGlobalOrg, fetchData, selectedService }) => {

  const [options, setOptions] = useState([]);

  const [btn, setBtn] = useState(false);

  const headerText = type === "add" ? 'Add Expense' : 'Edit Expense';

  const [errors, setErrors] = useState({});



  // console.log(getCurrentDate(),'getCurrentDate');

  const [formData, setFormData] = useState({
    name: '',
    expenseVendor: '',
    total: '',
    categoryID: '',
    orgId: '',
    notes: '',
    type: '',
    expenseDate: getCurrentDate(),
    expenseType: 0,
  });



  useEffect(() => {

    if (modalIsOpen === true) {
      fetchExpenseCategory()
    }

    if (type === 'edit' && selectedService) {
      setFormData({
        name: selectedService.name,
        expenseVendor: selectedService.expense_vendor,
        total: selectedService.total,
        categoryID: selectedService.expense_category_id,
        orgId: selectedService.org_id,
        id: selectedService.id,
        expenseType: selectedService.type,
        notes: selectedService.notes,
        expenseDate: selectedService.expense_date,
        type: type,
      });
    } else {
      const newOrgId = document.getElementById('orgId').value;
      setFormData({
        name: '',
        expenseVendor: '',
        total: '',
        categoryID: '',
        orgId: newOrgId,
        expenseType: 0,
        notes: '',
        expenseDate: getCurrentDate(),
        type: type,
      });
    }

    setErrors({});

  }, [modalIsOpen]);

  console.log(formData, 'formData');

  useEffect(() => {

    if (modalIsOpen === true) {
      fetchExpenseCategory()
      const newOrgId = document.getElementById('orgId').value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['categoryID']: "",
        ['orgId']: newOrgId,
      }));
    }

  }, [selectGlobalOrg]);

  const fetchExpenseCategory = async () => {

    const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);


    try {
      const result = await handleExpenseCategoryListing(initialOrgIds);
      if (result.data) {
        setOptions(result.data.data)
      }

    } catch (error) {
      console.log(error);
      showToastMessage('error', error.response.data.message)
    }


  }




  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate Expense Name
    if (!formData.name.trim()) {
      newErrors.name = "Expense name is required";
      isValid = false;
    }

    // Validate Category
    const categoryID = String(formData.categoryID).trim();
    if (!categoryID) {
      newErrors.categoryID = "Expense category name is required";
      isValid = false;
    }

    // Validate Total
    const total = String(formData.total).trim();
    if (!total) {
      newErrors.total = "Expense total is required";
      isValid = false;
    } else if (Number(formData.total) === 0) {
      newErrors.total = "Expense total cannot be zero";
      isValid = false;
    }

    // Validate Vendor Name
    if (!formData.expenseVendor.trim()) {
      newErrors.expenseVendor = "Staff name is required";
      isValid = false;
    }

    // Validate Notes
    if (!formData.notes.trim()) {
      newErrors.notes = "Expense note is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const processedValue = type === 'number' ? parseFloat(value) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));

    // if (name === 'expenseType') {

    //     console.log(checked,'checked');

    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         [name]: checked
    //     }));
    // }



    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const saveExpense = async (type) => {


    if (validateForm()) {

      setBtn(true);

      try {
        const reason = selectedService.reason;
        const result = await handleExpense(formData, reason);

        // console.log(result, 'result');

        if (result.data) {
          showToastMessage('success', result.data.message)
          fetchData()
          closeModal();
          setBtn(false);
        }

      } catch (error) {
        console.log(error);
        setBtn(false);
        showToastMessage('error', error.response.data.message)
      }



    }
  };

  return < >
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
        <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close-circle-outline menu-icon"></i></button>
      </div>
      <form className="mx-auto form mt-4" style={{ maxWidth: '100%' }}>
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="form-group">
              <label className="form-label">Expense Type:</label>
              <div className="form-radio-group">
                <div className="form-radio form-radio-box">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="basic"
                    name="expenseType"
                    value="0"
                    checked={formData.expenseType == 0}
                    onChange={
                      (e) => {
                        handleInputChange(e)
                      }
                    }
                  />
                  <label htmlFor="basic" className="form-check-label">
                    <span class="radio">
                      <i class="mdi mdi-check menu-icon"></i>
                    </span>
                    Cash Expense
                  </label>
                </div>
                <div className="form-radio form-radio-box">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="premium"
                    name="expenseType"
                    value="1"
                    checked={formData.expenseType == 1}
                    onChange={
                      (e) => {
                        handleInputChange(e)
                      }
                    }
                  />
                  <label htmlFor="premium" className="form-check-label">
                    <span class="radio">
                      <i class="mdi mdi-check menu-icon"></i>
                    </span>
                    Bank Expense
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">

            <div className="form-group">
              <label htmlFor="name" className="form-label">Expense Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                checked={formData.expenseType === '0'}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your expense name"
              />
              {errors.name && (
                <div className="text-danger">{errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="total" className="form-label">Expense Total:</label>
              <input
                type="text"
                className="form-control"
                id="total"
                name="total"
                onKeyDown={formatIntger}
                value={formData.total}
                checked={formData.expenseType === '1'}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your expense total"
              />
              {errors.total && (
                <div className="text-danger">{errors.total}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="categoryID" className="form-label">Service Category Name:</label>
              <select
                className="form-control form-select"
                id='categoryID'
                style={{ paddingRight: '40px' }}
                name='categoryID'
                value={formData.categoryID}
                onChange={(e) => handleInputChange(e)}
              >
                <option value="">Select Expense Category</option>
                {options && options.length > 0 ?
                  options
                    .filter(el => el.deleted_at === null)
                    .map((el, index) => (
                      <option key={index} value={el.id}>{el.name}</option>
                    ))
                  : ''
                }

              </select>
              {errors.categoryID && (
                <div className="text-danger">{errors.categoryID}</div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="form-group">
              <label htmlFor="expenseVendor" className="form-label">Expense Vendor:</label>
              <input
                type="text"
                className="form-control"
                id="expenseVendor"
                name="expenseVendor"
                value={formData.expenseVendor}
                onChange={(e) => handleInputChange(e)}
                placeholder="Expense Vendor"
                required
              />
              {errors.expenseVendor && (
                <div className="text-danger">{errors.expenseVendor}</div>
              )}

            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="form-group">
              <label htmlFor="expenseDate" className="form-label">Expense Date:</label>
              <input
                type="date"
                className="form-control"
                id="expenseDate"
                name="expenseDate"
                value={formData.expenseDate}
                onChange={(e) => handleInputChange(e)}
                placeholder="Expense Date"
              />

            </div>
          </div>
          <div class="col-12" id="button_add">
            <div class="form-group">
              <label for="notes">Expense Notes</label>
              <textarea
                class="form-control billing_remark"
                value={formData.notes}
                onChange={(e) => handleInputChange(e)}
                id="notes"
                name="notes"></textarea>
              {errors.notes && (
                <div className="text-danger">{errors.notes}</div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-12 text-end">
            <button type="button" disabled={btn}
              className="btn btn-primary btn-icon-left"
              onClick={() => saveExpense(type)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </>
}

export default ExpensesModal;