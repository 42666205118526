import React, { useEffect, useState } from 'react';
import Select from 'react-select';


export const SelectStaffService = ({ index, services, staffData, removeRow, value , handleFieldChange , errors}) => {

    return (
        <div className="" id="item_table">
                <div className="row align-items-center" id={index} key={index}>
                <div className="col-sm col-md col-lg col-xl">
                    <div className="form-group">
                        <label>Service</label>
                        <Select
                            name="serviceId"
                            options={services}
                            placeholder={'Select Service'}
                            isSearchable={true}
                            classNamePrefix="form"
                            className="form-control form-select"
                            value={services.reduce((acc, group) => {
                                if (group && group.options) {
                                    const selectedOption = group.options.find(option => option.value === value.serviceId);
                                    if (selectedOption) {
                                        acc = selectedOption;
                                    }
                                }
                                return acc;
                            }, null)}
                            onChange={(option) => {
                                handleFieldChange('serviceId', option.value, index)
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm col-md col-lg col-xl">
                    <div className="form-group">
                    <label>Staff</label>
                        <Select
                            name="staffId"
                            value={staffData.find(option => option.value === value.staffId)}
                            isSearchable={true}
                            options={staffData}
                            classNamePrefix="form"
                            placeholder={'Select Staff'}
                            className="form-control form-select"
                            onChange={option => handleFieldChange('staffId', option.value, index)}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-auto col-delete">
                    <button type="button" className="button button-primary button-icon" onClick={() => removeRow(index)}>
                        <i class="mdi mdi-delete-empty menu-icon bodytext-24"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};
